import React, { useEffect, useState, useContext } from "react";
import Profile from "./Profile";
import MyAddress from "./ProfileAddress";
import SellerPaymentForm from "./ProfilePayment";
import { useNavigate } from "react-router-dom";
import ApiEndpoint from "../../Configs/APIEndpoints.json";
import Alert from "../../Configs/Alerts.json";
import navigation from "../../Configs/Navigation.json";
import InputContext from "../Auth/InputContext";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../Component/Cookies/Cookie";
import Toast from "../../Component/Toastify/Toast";
import BusinessDetails from "./BusinessDetails";

const API_HOST = process.env.REACT_APP_HOST_API_URL;

const ProfileNavigation = () => {
  const { showToast } = Toast();
  const navigate = new useNavigate();
  const contextObj = useContext(InputContext);
  const [isBusinessOwner, setIsBusinessOwner] = useState(false);
  const [sellerStatus, setSellerStatus] = useState(false);
  const [activePage, setActivePage] = useState("");
  const [userName, setUserName] = useState("");
  const [firstChar, setFirstChar] = useState("");
  const [email, setEmail] = useState("");
  const [profileCompletion, setProfileCompletion] = useState("");

  // Handle window re-size
  const [windowWidth, setWindowWidth] = useState(window.outerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.outerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [featureFlags, setFeatureFlags] = useState({
    sellerCommonNavigationbar: false,
    sellerProfileinformation: false,
    sellerProfilePaymentinformation: false,
    sellerProfilePickupaddress: false,
    SellerMultivendor: false,
  });

  // Hamburger button logic implementaion for small screen.
  useEffect(() => {
    const featureFlags = contextObj.inputValue.featureFlags;
    if (featureFlags) {
      setFeatureFlags((prev) => ({
        ...prev,
        sellerCommonNavigationbar: featureFlags.seller_common_navigationbar,
        sellerProfileinformation:
          featureFlags.seller_profile_profileinformation,
        sellerProfilePaymentinformation:
          featureFlags.seller_profile_paymentinformation,
        sellerProfilePickupaddress: featureFlags.seller_profile_pickupaddress,
        SellerMultivendor: featureFlags.seller_multivendor,
      }));
      if (featureFlags.seller_profile_profileinformation === true) {
        setActivePage("profile");
      } else if (featureFlags.seller_profile_paymentinformation == true) {
        setActivePage("payment");
      } else if (featureFlags.seller_profile_pickupaddress == true) {
        setActivePage("address");
      } else if (featureFlags.seller_multivendor == true) {
        setActivePage("shoponwer");
      }
    }
  }, [contextObj.inputValue.featureFlags]);

  // Seller Profile Status
  useEffect(() => {
    if (contextObj.inputValue.isSellerVerified === "true") {
      setSellerStatus(false);
    }
    if (contextObj.inputValue.isBusinessOwner === true) {
      setIsBusinessOwner(true);
    }
  }, [
    contextObj.inputValue.isSellerVerified,
    contextObj.inputValue.isBusinessOwner,
  ]);

  const handleNavigation = (page) => {
    setActivePage(page);
  };

  // Handle seller first char
  useEffect(() => {
    setFirstChar(contextObj.inputValue.firstChar);
  }, [contextObj.inputValue.firstChar]);

  const handleLogout = async () => {
    const token = getDataFromCookies("R3p7uLw9Xk");
    if (!token) {
      showToast("info", Alert.alertForSomethingWrong);
      navigate(`${navigation.home}`);
    } else {
      const response = await fetch(
        `${API_HOST}/${ApiEndpoint.SellersLogoutApi}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = await response.json();
      sessionStorage.removeItem("home_input");

      if (res.status === 200 || res.status === 401) {
        removeDataFromCookie("R3p7uLw9Xk");
        removeDataFromCookie("isLoggedIn");
        removeDataFromCookie("fullName");
        removeDataFromCookie("firstChar");
        removeDataFromCookie("email");
        showToast("success", Alert.alertForLogout);
      } else {
        showToast("info", "Something went wrong");
      }
    }
  };

  return (
    <div className="container">
      {sellerStatus && !isBusinessOwner && (
        <div className="seller-verification-line">
          <p>
            Your account verification is pending. To proceed, please{" "}
            <span
              className="complete-your-profile"
              onClick={() => {
                navigate(`${navigation.profile}`);
              }}
            >
              ensure your Profile Information and Pickup Address is complete
            </span>{" "}
            and wait for the admin verification call.
          </p>
        </div>
      )}
      {sellerStatus && isBusinessOwner && (
        <div className="seller-verification-line">
          <p>
            Your account verification is pending. To proceed, please{" "}
            <span
              className="complete-your-profile"
              onClick={() => {
                navigate(`${navigation.profile}`);
              }}
            >
              ensure your Profile Information, Payment Information, Pickup
              Address and Business Details is complete
            </span>{" "}
            and wait for the admin verification call.
          </p>
        </div>
      )}
      <div className="container-heading">
        <h2>Your Profile</h2>
      </div>

      <div className="profile-container">
        <div className="profile-navigation">
          {((firstChar && userName) || email) && (
            <div className="profile-contact">
              {firstChar && (
                <div className="profile-first-char">{firstChar}</div>
              )}
              {(userName || email) && (
                <div>
                  {userName == "null null" ? <p> Hi Seller </p>: <p>{userName}</p> }
                  {email && <p>{email}</p>}
                </div>
              )}
            </div>
          )}
          {featureFlags.sellerProfileinformation && (
            <li
              id="SideNavigationProfileInformation"
              className={`${activePage === "profile" && "active"}`}
              onClick={() => handleNavigation("profile")}
            >
              {windowWidth > 600 ? "Profile Information" : "Profile"}
            </li>
          )}
          {featureFlags.sellerProfilePaymentinformation && (
            <li
              id="SideNavigationPaymentInformation"
              className={`${activePage === "payment" && "active"}`}
              onClick={() => handleNavigation("payment")}
            >
              {windowWidth > 600 ? "Payment Information" : "Payment"}
            </li>
          )}
          {featureFlags.sellerProfilePickupaddress && (
            <li
              id="SideNavigationPickupAddress"
              className={`${activePage === "address" && "active"}`}
              onClick={() => handleNavigation("address")}
            >
              {windowWidth > 600 ? "Pickup Address" : "Pickup"}
            </li>
          )}
          {featureFlags.SellerMultivendor && (
            <li
              id="SideNavigationShopOwner"
              className={`${activePage === "shop" && "active"}`}
              onClick={() => handleNavigation("shop")}
            >
              {windowWidth > 600 ? "Business Details" : "Business"}
            </li>
          )}
          <li
            id="profileNavigationLogout"
            className="profile-navigation-logout"
            onClick={async (e) => {
              await handleLogout();
              setTimeout(() => {
                navigate(`${navigation.home}`);
              }, 1000);
            }}
          >
            Logout
          </li>
        </div>
        <div className="profile-content">
          {profileCompletion && profileCompletion!=100 && (
            <div className="progress-bar" title="Profile Completion">
              <div className="progress-bar__background">
                <div
                  className="progress-bar__progress"
                  style={{ width: `${profileCompletion}%` }}
                >
                  <span className="progress-bar__text">{profileCompletion}%</span>
                </div>
              </div>
            </div>
          )}
          {activePage === "profile" && (
            <Profile
              profileUnverified={setSellerStatus}
              setUserName={setUserName}
              setEmail={setEmail}
              setFirstChar={setFirstChar}
              profileComplete={setProfileCompletion}
            />
          )}
          {activePage === "payment" && (
            <SellerPaymentForm profileUnverified={setSellerStatus} />
          )}
          {activePage === "address" && (
            <MyAddress profileUnverified={setSellerStatus} />
          )}
          {activePage === "shop" && (
            <BusinessDetails
              profileUnverified={setSellerStatus}
              isBusinessOwners={setIsBusinessOwner}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileNavigation;
