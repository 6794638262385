import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import { getDataFromCookies } from "../../Component/Cookies/Cookie";

//import Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import InputContext from "../Auth/InputContext";
import navigation from "../../Configs/Navigation.json";
import ApiEndpoint from "../../Configs/APIEndpoints.json";
import Alert from "../../Configs/Alerts.json";
import loadingImgPath from "../../Assets/rolling.svg";
import Toast from "../../Component/Toastify/Toast";
import imagePlaceHolder from "../../Assets/image-placeholder.png";
import "./donation.css";

// import constant
const API_HOST = process.env.REACT_APP_HOST_API_URL;
const APP_INFRA = process.env.REACT_APP_INFRA.toLowerCase();
const S3IMG_HOST = process.env.REACT_APP_S3BUCKET_IMG_HOST;
const IMG_HOST =
  APP_INFRA == "staging" || APP_INFRA == "production" ? S3IMG_HOST : API_HOST;

const NgoDonate = () => {
  const navigate = useNavigate();
  const [ngo, setNgo] = useState([]);
  const [products, setProducts] = useState([]);
  const [mappedProducts, setMappedProducts] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const { ngoId } = useParams(); // orderId from Order List
  useEffect(() => {
    fetchNgo(ngoId);
    fetchProducts();
  }, []);
  const contextObj = useContext(InputContext);
  const { showToast } = Toast();

  const [executeHandleSubmit, setExecuteHandleSubmit] = useState(false);
  useEffect(() => {
    if (executeHandleSubmit) handledonateSubmit();
  }, [executeHandleSubmit]);

  // fetch NGO details
  const fetchNgo = async (ngoId) => {
    const url = `${API_HOST}/${ApiEndpoint.SellersNgoApi}/${ngoId}`;
    const token = getDataFromCookies("R3p7uLw9Xk");
    if (!token) {
      navigate(`${navigation.home}`);
      showToast("info", Alert.alertForLoginExpired);
    }
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 200) {
          setNgo(res.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error.message.includes("Failed to fetch")) {
          // navigate("/error");
          contextObj.setInput("serviceUnavailable", true);
        } else {
          window.location.href = "/error";
        }
        console.error(error);
      });
  };

  // fetch seller products to display in select options
  const fetchProducts = async () => {
    const url = `${API_HOST}/${ApiEndpoint.SellersDonatableProductsApi}`;
    const token = getDataFromCookies("R3p7uLw9Xk");
    if (!token) {
      navigate(`${navigation.home}`);
      showToast("info", Alert.alertForLoginExpired);
    }
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 200) {
          setProducts(res.data);
        } else if (res.status === 404) {
          console.error(res);
        }
      })
      .catch((error) => {
        if (error.message.includes("Failed to fetch")) {
          // navigate("/error");
          contextObj.setInput("serviceUnavailable", true);
        } else {
          window.location.href = "/error";
        }
        console.error(error);
      });
  };

  useEffect(() => {
    const mappedProductsArray =
      products &&
      products.map((product) => ({
        value: product.id,
        label: product.name,
        img: product.thumb_image,
      }));
    setMappedProducts(mappedProductsArray);
  }, [products]);

  const MySelect = () => {
    return (
      <Select
        id="sellers-selectTag-forDonateProduct"
        options={mappedProducts}
        placeholder="Search or select from list"
        onChange={handleSelectChange}
        className="product-select-input"
        required
      />
    );
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOptions((prevOptions) => {
      const isAlreadySelected = prevOptions.some(
        (option) => option.value === selectedOption.value
      );
      if (isAlreadySelected) return prevOptions;
      const updatedMappedProducts = mappedProducts.filter(
        (product) => product.value !== selectedOption.value
      );
      setMappedProducts(updatedMappedProducts);
      return [selectedOption, ...prevOptions];
    });
  };

  const handleRemoveProduct = (productId) => {
    setSelectedOptions((prevSelectedOptions) => {
      const removedOption = prevSelectedOptions.find(
        (option) => option.value === productId
      );

      const updatedSelectedOptions = prevSelectedOptions.filter(
        (option) => option.value !== productId
      );

      const sortedOptions = updatedSelectedOptions.sort(
        (a, b) =>
          prevSelectedOptions.indexOf(a) - prevSelectedOptions.indexOf(b)
      );

      setMappedProducts((prevMappedProducts) => [
        ...prevMappedProducts,
        removedOption,
      ]);

      return sortedOptions;
    });
  };

  // POST API Integration - seller product donation
  const handledonateSubmit = () => {
    const url = `${API_HOST}/${ApiEndpoint.SellersDonateProductApi}`;
    const token = getDataFromCookies("R3p7uLw9Xk");
    if (!token) {
      navigate(`${navigation.home}`);
      showToast("info", Alert.alertForLoginExpired);
    }

    let ids = [];
    if (selectedOptions.length > 0) {
      ids = selectedOptions.map((product) => product.value);
    }

    // validations
    if (selectedOptions.length <= 0) {
      showToast("error", Alert.alertForselectingProduct);
      setExecuteHandleSubmit(false);
      return;
    }
    if (!ngo) {
      console.error("Invalid NGO details");
      setExecuteHandleSubmit(false);
      return;
    }
    if (!products) {
      console.error("Invalid products details");
      setExecuteHandleSubmit(false);
      return;
    }

    let payload = {
      ngo_id: ngo.id,
      product_id: ids,
    };
    fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((res) => {
        setExecuteHandleSubmit(false);
        if (res.status === 201) {
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "Donation");
          showToast("success", Alert.alertForDonationRequest);
          setSelectedOptions([]);

          // update products list
          for (let i = 0; i < ids.length; i++) {
            const updatedProducts = products.filter(
              (product) => product.id !== ids[i]
            );
            setProducts(updatedProducts);
          }
        } else if (res.status === 404) {
          showToast("info", res.message);
          console.error(res);
        }
      })
      .catch((error) => {
        if (error.message.includes("Failed to fetch")) {
          // navigate("/error");
          contextObj.setInput("serviceUnavailable", true);
        } else {
          window.location.href = "/error";
        }
        console.error(error);
      });
  };

  return (
    <div className="container">
      <div
        className="container-heading"
        onClick={() => {
          navigate(`${navigation.ngos}`);
        }}
      >
        <h2>
          <span>
            <svg
              width="15"
              height="10"
              viewBox="0 0 15 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 4.11623V5.884H3.44772L6.36769 8.75001L5.09417 10L0 5.00003L5.09417 0L6.36769 1.25L3.44751 4.11623H15Z"
                fill="#939393"
              />
            </svg>
          </span>
          Donate Now
        </h2>
      </div>
      {loading ? (
        <div className="MobileImageContainerForDonationToday">
          <img className="loader" src={loadingImgPath} />
        </div>
      ) : (
        <div className="donate-today-body">
          <div className="donate-ngo-card">
            <div className="card-head">
              <div className="donation-row">
                <div className="ngo-image-container">
                  <img
                    id="sellers-ngoImage"
                    className="ngo-profile-image"
                    src={
                      ngo.image ? `${IMG_HOST}/${ngo.image}` : imagePlaceHolder
                    }
                    alt={"image"}
                  />
                </div>
                <div className="ngo-primary-info">
                  <h3 id="sellers-ngoTitle" className="ngo-titile">
                    {ngo.org_name}
                  </h3>
                  <p className="ngo-tag-line">Bringing hope, changing lives.</p>
                  <p>
                    <span className="bold">Email:</span>{" "}
                    <a
                      id="sellers-ngoEamailAndPhone"
                      className="ngo-phone"
                      href={`mailto:${ngo.org_email}`}
                    >
                      {ngo.org_email}
                    </a>
                  </p>
                  <p>
                    <span className="bold">Phone:</span>{" "}
                    <a
                      id="sellers-ngoEamailAndPhone"
                      className="ngo-email"
                      href={`tel:${ngo.org_phone}`}
                    >
                      {ngo.org_phone}
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="card-body">
              <p className="addresDiv">
                <span className="bold">Address:</span>{" "}
                <span
                  id="sellers-ngoAddress-forDonations"
                  className="ngoAddress-Title"
                >
                  {ngo.address1}
                </span>
              </p>
            </div>
            <div className="card-foot"></div>
          </div>
          <div className="select-products">
            <div className="svgIconForDontionDiv">
              <label className="listHeading">
                Product List ({products.length})
              </label>
            </div>

            <div>
              <label>Select product</label>
              <MySelect />
            </div>

            {selectedOptions &&
              selectedOptions.length > 0 &&
              selectedOptions.map((product, index) => (
                <div
                  id="seller-ngoProduct-DonateNow"
                  className="product"
                  key={index}
                >
                  <div className="image-nameDiv">
                    <img
                      id="sellers-productImage-forDonation"
                      className="product-thumb-img"
                      src={`${IMG_HOST}/${product.img}`}
                      alt=""
                    />
                    <h4
                      id="sellers-productName-forDonation"
                      className="product-name"
                    >
                      {product.label}
                    </h4>
                  </div>
                  <button
                    id="sellers-deleteButton-ngoProducts"
                    onClick={() => handleRemoveProduct(product.value)}
                    className="product-delete-btn"
                  >
                    <FontAwesomeIcon icon={faTrashCan} />
                  </button>
                </div>
              ))}

            <div className="donateNowButtonDiv">
              <button
                id="sellers-donateProductButton"
                onClick={() => {
                  setExecuteHandleSubmit(true);
                }}
                className="donate-now"
              >
                Donate Now
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NgoDonate;
