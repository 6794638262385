import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import InputContext from "../Auth/InputContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import navigation from "../../Configs/Navigation.json";
import ApiEndpoint from "../../Configs/APIEndpoints.json";
import Alert from "../../Configs/Alerts.json";
import ReactQuill from "react-quill";
import { getDataFromCookies } from "../../Component/Cookies/Cookie";
import { ReactComponent as RollingSvg } from "../../Assets/rolling.svg";
import Toast from "../../Component/Toastify/Toast";
import Cropper from "react-cropper";
import { faTrashAlt, faXmark } from "@fortawesome/free-solid-svg-icons";
import loadingImgPath from "../../Assets/rolling.svg";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import "cropperjs/dist/cropper.css";
import "react-quill/dist/quill.snow.css";
import "./product.css";
import CategoriesDescription from "./CategoryDescription/categoriesDescription";

// import constant
const API_HOST = process.env.REACT_APP_HOST_API_URL;
const BUYER_APP = process.env.REACT_APP_BUYER_DOMAIN;
const APP_INFRA = process.env.REACT_APP_INFRA.toLowerCase();
const S3IMG_HOST = process.env.REACT_APP_S3BUCKET_IMG_HOST;
const IMG_HOST = APP_INFRA == "production" ? S3IMG_HOST : API_HOST;

const NewProductsVariant = (props) => {
  const [pageName, setPageName] = useState("");
  const inputRef = useRef(null);
  const { slug } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { showToast } = Toast();
  const contextObj = useContext(InputContext);
  const [productId, setProductId] = useState("");
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [sellerStatus, setSellerStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [showCategoriesPopup, setShowCategoryPopup] = useState(false);
  const [openSeoEdit, setOpenSeoEdit] = useState(false);
  const metaTitleMaxLength = 70;
  const metaDescMaxLength = 320;
  const [metaTitleRemaining, setMetaTitleRemaining] =
    useState(metaTitleMaxLength);
  const [metaDescRemaining, setMetaDescRemaining] = useState(metaDescMaxLength);
  const [productUpdate, setProductUpdate] = useState(0);
  let [variantsList, setVariantsList] = useState([]);
  const [weightUnit, setWeightUnit] = useState("kg");
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState({
    modal: false,
    variantIndex: null,
    stock_id: null,
    loading: false,
  });
  let [variantsProductList, setVariantsProductList] = useState([]);
  // image handling
  const [showImgUpModal, setShowImgUpModal] = useState(false);
  const [prodImageToEdit, setProdImageToEdit] = useState({
    imgFile: null,
    imgName: null,
    imgNameError: null,
    index: null,
  });
  const [rawImgName, setRawImgName] = useState("");
  const [rawImage, setRawImage] = useState(null);
  const cropperRef = useRef(null);
  // calculate estimated shipping cost
  const [isCheckedShippingCost, setIsCheckedShippingCost] = useState(false);
  const [enableCustomPincode, setEnableCustomPincode] = useState(false);
  const [estimatedShippingCost, setEstimatedShippingCost] = useState("");
  const [shippingCostLoading, setShippingCostLoading] = useState(false);
  const [shippingCostPincode, setShippingCostPincode] = useState("");
  const [shippingCostCustomPincode, setShippingCostCustomPincode] =
    useState("");
  const [dataChanged, setDataChanged] = useState(false);
  const [imgContainer, setImgContainer] = useState({
    thumb_image: null,
    front_image: null,
    left_image: null,
    right_image: null,
    back_image: null,
    top_image: null,
  });
  const [selectedValue, setSelectedValue] = useState("");
  const [applicableAgeGroupData, setapplicableAgeGroupData] = useState([]);
  const [conditionData, setConditionData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [product, setProduct] = useState({
    name: null,
    description: editorContent,
    thumb_image: null,
    front_image: null,
    left_image: null,
    right_image: null,
    back_image: null,
    top_image: null,
    video_url: null,
    product_price: null,
    current_market_price: null,
    sku: null,
    // hsn: null,
    barcode: null,
    weight: null,
    length: null,
    width: null,
    height: null,
    is_donatable: 0,
    is_negotiable: "1",
    status: "Active",
    condition: null,
    age_group_id: null,
    brand_id: null,
    category_id: null,
    is_denied: null,
    denied_note: null,
    meta_title: null,
    meta_description: null,
  });

  const [errors, setErrors] = useState({
    has_error: false,
    name: "",
    description: "",
    thumb_image: "",
    front_image: "",
    left_image: "",
    right_image: "",
    back_image: "",
    top_image: "",
    video_url: "",
    product_price: "",
    current_market_price: "",
    sku: "",
    // hsn: "",
    barcode: "",
    weight: "",
    length: "",
    width: "",
    height: "",
    is_negotiable: "",
    is_donatable: "",
    status: "",
    condition: "",
    age_group_id: "",
    brand_id: "",
    category_id: "",
    estimated_shipping_cost: "",
    meta_title: "",
    meta_description: "",
  });

  class VariantProduct {
    constructor(
      is_default = 0,
      new_stock = false,
      update_stock = false,
      variantListVisible = false,
      variantValuesListVisible = false,
      stock_id = null,
      selectedVariant = null,
      variant_values = null,
      product_price = null,
      current_market_price = null,
      thumb_image = null,
      front_image = null,
      left_image = null,
      right_image = null,
      back_image = null,
      top_image = null,
      video_url = null,
      sku = null,
      // hsn = null,
      barcode = null,
      quantity = null,
      product_price_as_first = false,
      current_market_price_as_first = false,
      selectedVariant_error = null,
      variant_values_error = null,
      product_price_error = null,
      current_market_price_error = null,
      thumb_image_error = null,
      front_image_error = null,
      left_image_error = null,
      right_image_error = null,
      back_image_error = null,
      top_image_error = null,
      video_url_error = null,
      sku_error = null,
      hsn_error = null,
      barcode_error = null,
      quantity_error = null
    ) {
      this.is_default = is_default;
      this.new_stock = new_stock;
      this.update_stock = update_stock;
      this.variantListVisible = variantListVisible;
      this.selectedVariant = selectedVariant;
      this.stock_id = stock_id;
      this.variantValuesListVisible = variantValuesListVisible;
      this.variant_values = variant_values;
      this.product_price = product_price;
      this.current_market_price = current_market_price;
      this.thumb_image = thumb_image;
      this.video_url = video_url;
      this.sku = sku;
      this.barcode = barcode;
      this.quantity = quantity;
      this.front_image = front_image;
      this.left_image = left_image;
      this.right_image = right_image;
      this.back_image = back_image;
      this.top_image = top_image;
      this.product_price_as_first = product_price_as_first;
      this.current_market_price_as_first = current_market_price_as_first;
      // errors
      this.selectedVariant_error = selectedVariant_error;
      this.variant_values_error = variant_values_error;
      this.product_price_error = product_price_error;
      this.current_market_price_error = current_market_price_error;
      this.thumb_image_error = thumb_image_error;
      this.front_image_error = front_image_error;
      this.left_image_error = left_image_error;
      this.right_image_error = right_image_error;
      this.back_image_error = back_image_error;
      this.top_image_error = top_image_error;
      this.video_url_error = video_url_error;
      this.sku_error = sku_error;
      this.hsn_error = hsn_error;
      this.barcode_error = barcode_error;
      this.quantity_error = quantity_error;
    }
  }

  const handleCategoryDetailsPopup = () => {
    setShowCategoryPopup(true);
  };

  const removeEmptyHTMLTags = (input) => {
    const emptyTagsAtStart = /^<\w+>(\s*|<br>)<\/\w+>/;
    const emptyTagsAtEnd = /<\w+>(\s*|<br>)<\/\w+>$/;
    while (emptyTagsAtStart.test(input)) {
      input = input.replace(emptyTagsAtStart, "");
    }

    while (emptyTagsAtEnd.test(input)) {
      input = input.replace(emptyTagsAtEnd, "");
    }

    return input;
  };

  useMemo(() => {
    if (location.pathname === "/products/new") {
      setPageName("Add");
      setProduct({
        name: "",
        description: "",
        sku: "",
        barcode: "",
        weight: "",
        length: "",
        width: "",
        height: "",
        is_donatable: "",
        is_negotiable: "1",
        status: "Active",
        condition: "",
        age_group_id: "",
        brand_id: "",
        category_id: "",
        is_denied: "",
        denied_note: "",
        meta_title: "",
        meta_description: "",
      });
      setVariantsProductList([]);
    } else if (location.pathname.includes("/products/")) {
      setPageName("Edit");
    }
  }, [location.pathname]);

  const closedCategoryDetailsPopup = () => {
    setShowCategoryPopup(false);
  };
  // Video URL validation
  let isValidVideoLink = (video) => {
    if (video === null) return;
    if (video.length === 0) {
      return true;
    } else {
      let videoLinkRegex =
        /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be|vimeo\.com|dailymotion\.com|vine\.co|v\.youku\.com|tiktok\.com|facebook\.com)\/.+/.test(
          video
        );
      return videoLinkRegex;
    }
  };
  //   new variant

  const variantsURL = `${API_HOST}/api/seller/product/variants`;
  const checkFirstVariantPrice = (index, field) => {
    let fieldToCheck = "";
    let firstVarientValue = null;
    let errorFieldName = null;
    if (field == "current_market_price_as_first") {
      fieldToCheck = "current_market_price";
      errorFieldName = "current_market_price_error";
    }
    if (field == "product_price_as_first") {
      fieldToCheck = "product_price";
      errorFieldName = "product_price_error";
    }

    if (
      variantsProductList &&
      variantsProductList.length > 0 &&
      variantsProductList[0]
    ) {
      firstVarientValue = variantsProductList[0][fieldToCheck];
    }
    
    if (firstVarientValue !== null && firstVarientValue !== "") {
      let updateReadOnly = variantsProductList[0][field];
      updateVariantField(index, fieldToCheck, firstVarientValue);
      updateVariantField(index, field, !updateReadOnly);
      updateVariantField(index, errorFieldName, "");
    } else {
      showToast(
        "error",
        `Update ${fieldToCheck.split("_").join(" ")} in the first variant.`
      );
    }
  };
  const addNewVariant = () => {
    return new Promise((resolve) => {
      let newVariant = new VariantProduct();
      setVariantsProductList((prevVariants) => {
        if (pageName == "Add") {
          if (prevVariants.length === 0) {
            newVariant.is_default = 1;
          } else {
            newVariant.is_default = 0;
          }
        }
        const updatedVariants = [...prevVariants, newVariant];
        resolve(updatedVariants);
        return updatedVariants;
      });
    });
  };

  const updateVariantField = (index, field, value) => {
    return new Promise((resolve) => {
      setVariantsProductList((prevVariants) => {
        const updatedVariants = [...prevVariants];
        if (updatedVariants[index]) {
          updatedVariants[index][field] = value;
        }
        return updatedVariants;
      });
      // Resolve the promise after the state update is completed
      resolve();
    });
  };

  const filterVariant = async (index) => {
    setShowRemoveConfirmation((prev) => ({
      loading: true,
    }));

    if (showRemoveConfirmation.stock_id) {
      deleteStock(showRemoveConfirmation.stock_id);
    } else {
      setVariantsProductList((prevVariants) =>
        prevVariants.filter((_, i) => i !== showRemoveConfirmation.variantIndex)
      );
      cancelRemoveVariant();
    }
  };
  const cancelRemoveVariant = () => {
    setShowRemoveConfirmation((prev) => ({
      ...prev,
      modal: false,
      variantIndex: null,
      stock_id: null,
      loading: false,
    }));
  };

  const fetchVariantsData = (url) => {
   
    if (!token) {
      navigate(`${navigation.home}`);
      return;
    }

    fetch(`${url}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 200) {
          let data = res.data;
          variantsList = data;
          setVariantsList(data);
        } else {
          console.error(res);
        }
      })
      .then(async () => {
        if (props.page === "edit") {
          const urls = slug.split("-");

          const productId = urls[urls.length - 2];
          setProductId(productId);
          await getProductDetails(productId);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  // general functions
  const handleWheel = (e) => {
    e.preventDefault();
    document.activeElement.blur();
  };

  // length error
  const isValidNumeric = (value) => {
    const numeric = /^[0-9]+$/;
    const isValid = numeric.test(value);
    return isValid;
  };

  const token = getDataFromCookies("R3p7uLw9Xk");
  if (!token) {
    navigate(`${navigation.home}`);
    showToast("info", Alert.alertForLoginExpired);
  }

  //weight units
  const weightUnitsChangeHandler = (event) => {
    setWeightUnit(event.target.value);
  };

  //convert product weight gm to kg
  const convertWeightGmToKg = (weight) => {
    const convertedWeight = (weight / 1000).toFixed(3);
    return parseFloat(convertedWeight);
  };

  // tooltip visibility
  const handleMouseEnter = () => {
    setTooltipVisible(true);
  };
  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  const loadImgOnModal = (imgName, img) => {
    setShowImgUpModal(true);
    setRawImgName(imgName);
    setRawImage(URL.createObjectURL(img));
  };

  const convertBase64ToFile = (base64Image, file) => {
    if (base64Image.startsWith("data:image/")) {
      const binaryString = atob(base64Image.split(",")[1]);
      const arrayBuffer = new ArrayBuffer(binaryString.length);
      const byteArray = new Uint8Array(arrayBuffer);
      for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
      }
      const fileSize = arrayBuffer.byteLength;
      const customFile = new File([arrayBuffer], file.name, {
        type: file.type,
        lastModified: file.lastModified,
      });
      Object.defineProperty(customFile, "size", {
        value: fileSize,
      });

      return customFile;
    }
  };

  const cropImage = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const croppedImg = cropperRef.current?.cropper
        .getCroppedCanvas()
        .toDataURL();

      const customFile = convertBase64ToFile(
        croppedImg,
        prodImageToEdit.imgFile
      );
      if (customFile) {
        updateVariantField(
          prodImageToEdit.index,
          prodImageToEdit.imgName,
          customFile
        );
      } else {
        updateVariantField(
          prodImageToEdit.index,
          prodImageToEdit.imgNameError,
          "Invalid image format"
        );
      }
    }
    // reset modal
    setShowImgUpModal(false);
    setRawImgName("");
    setRawImage(null);
    setProdImageToEdit((prev) => ({
      ...prev,
      imgFile: null,
      imgName: null,
      imgNameError: null,
      index: null,
    }));
  };

  const resetInputType = (imgName) => {
    document.getElementById(imgName).value = "";
  };

  // Get All Category Element;
  const getAllCategoriesData = async () => {
    await fetch(`${API_HOST}/${ApiEndpoint.SellersGetCategoryApi}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const res = data;
        if (res.status === 200) {
          setCategoryData(res.data);
          setSellerStatus(false);
          contextObj.setInput("isSellerVerified", "true");
        } else if (res.status === 401 && res.message.includes("not verified")) {
          setSellerStatus(true);
          contextObj.setInput("isSellerVerified", "false");
        }
      })
      .catch((error) => {
        console.error(error);
        if (error.message.includes("Failed to fetch")) {
          // navigate("/error");
          contextObj.setInput("serviceUnavailable", true);
        } else {
          window.location.href = "/error";
        }
      });
  };

  // Get All Brand List;
  const getAllBrandList = async () => {
    const token = getDataFromCookies("R3p7uLw9Xk");
    await fetch(`${API_HOST}/${ApiEndpoint.SellersGetBrandApi}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const dataArray = data.data;
        if (Array.isArray(dataArray)) {
          const sortedArray = dataArray.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          setBrandData(sortedArray);
        }
      })
      .catch((error) => {
        console.error(error);
        if (error.message.includes("Failed to fetch")) {
          contextObj.setInput("serviceUnavailable", true);
        } else {
          window.location.href = "/error";
        }
      });
  };

  // Get All Conditions List;
  const getAllConditionsList = async () => {
    await fetch(`${API_HOST}/${ApiEndpoint.SellersGetConditionApi}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const dataArray = data.data;
        if (Array.isArray(dataArray)) {
          const titlesArray = dataArray.map((item) => item.title);
          setConditionData(titlesArray);
        }
      })
      .catch((error) => {
        console.error(error);
        if (error.message.includes("Failed to fetch")) {
          contextObj.setInput("serviceUnavailable", true);
        } else {
          window.location.href = "/error";
        }
      });
  };

  // Get All Conditions List;
  const getAllAplplicableAgeGroupList = async () => {
    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellersGetAgeApi}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    const res = await response.json();
    if (res.status === 200) {
      setapplicableAgeGroupData(res.data);
    } else {
      navigate("/error");
    }
  };

  // Seller Profile Status
  useEffect(() => {
    if (contextObj.inputValue.isSellerVerified === "true") {
      setSellerStatus(false);
    }
  }, [contextObj.inputValue.isSellerVerified]);

  // Get product details
  const getProductDetails = async (productId) => {
    if (!productId) return;

    try {
      setIsLoading(true);
      const response = await fetch(
        `${API_HOST}/${ApiEndpoint.SellersProductsApi}/${productId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      const res = await response.json();

      if (res.status === 200) {
        setProduct(res.data);

        if (res.data) {
          // Set SEO inputs
          if (res.data.meta_title) {
            setMetaTitleRemaining(
              metaTitleMaxLength - res.data.meta_title.length
            );
          }
          if (res.data.meta_description) {
            setMetaDescRemaining(
              metaDescMaxLength - res.data.meta_description.length
            );
          }
          if (res.data.meta_title || res.data.meta_description) {
            setOpenSeoEdit(true);
          }

          // Change default status
          setProduct((prev) => ({
            ...prev,
            status: res.data.status === "Draft" ? "Draft" : "Active",
          }));

          // Process stocks data if available
          if (res.data.stocks && res.data.stocks.length > 0) {
            let stocks = res.data.stocks;
            setVariantsProductList([]);
            stocks.forEach(async (product, index) => {
              await addNewVariant();
              updateVariantField(index, "is_default", product.is_default);
              updateVariantField(index, "stock_id", product.id);
              updateVariantField(index, "quantity", product.quantity);
              updateVariantField(index, "video_url", product.video_url);
              updateVariantField(index, "product_price", product.product_price);
              updateVariantField(
                index,
                "current_market_price",
                product.current_market_price
              );
              updateVariantField(index, "sku", product.sku);
              updateVariantField(index, "barcode", product.barcode);

              // Update images
              updateVariantField(index, "thumb_image", product.thumb_image);
              updateVariantField(index, "front_image", product.images_url[0]);
              updateVariantField(index, "left_image", product.images_url[1]);
              updateVariantField(index, "right_image", product.images_url[2]);
              updateVariantField(index, "back_image", product.images_url[3]);
              updateVariantField(index, "top_image", product.images_url[4]);

              if (product.variant_id && product.variant_value_id) {
                getSelectedVarient(
                  index,
                  product.variant_id,
                  product.variant_value_id
                );
              }
            });
          }          
        }
      } else {
        console.error("Error fetching product details:", res);
      }
    } catch (error) {
      console.error("An error occurred while fetching product details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getSelectedVarient = (index, variant_id, variant_value_id) => {
    variantsList.forEach((variant) => {
      if (variant.id == variant_id) {
        updateVariantField(index, "selectedVariant", variant);
        variant.variant_values.map((value) => {
          if (value.id == variant_value_id) {
            updateVariantField(index, "variant_values", value);
          }
        });
      }
    });
  };

  useEffect(() => {
    const token = getDataFromCookies("R3p7uLw9Xk");
    fetchData();
  }, []);

  const fetchData = async () => {
    if (props.page === "edit") {
      setIsLoading(true);
      setPageName("Edit");
    }
    fetchVariantsData(variantsURL);

    if (props.page === "new") {
      setPageName("Add");
    }

    const token = getDataFromCookies("R3p7uLw9Xk");
    if (!token) {
      showToast("error", Alert.alertForselectingProduct);
      navigate(`${navigation.home}`);
    }
    if (inputRef.current !== null) inputRef.current.focus();
    getAllCategoriesData();
    getAllBrandList();
    getAllConditionsList();
    getAllAplplicableAgeGroupList();

    const pincode = getDataFromCookies("pincode");
    if (pincode) {
      setShippingCostPincode(pincode);
    }
  };

  const convertToAmpersand = (text) => {
    // Replace blank spaces with "&nbsp;" and line breaks with "<br>"
    return text.replace(/\s/g, "&nbsp;").replace(/\n/g, "<br>");
  };

  const getEstimatedShippingCost = async () => {
    let errorHas = false;
    // Length error
    if (product.length === null || product.length === "") {
      setErrors((prev) => ({
        ...prev,
        length: Alert.alertForLength,
      }));
      errorHas = true;
    } else if (
      product.length != "" &&
      product.length != null &&
      !isValidNumeric(product.length)
    ) {
      setErrors((prev) => ({
        ...prev,
        length: Alert.invalidFormat,
      }));
      errorHas = true;
    } else if (
      product.length != "" &&
      product.length != null &&
      (product.length === 0 ||
        product.length === "0" ||
        product.length < 0.1 ||
        product.length > 300)
    ) {
      setErrors((prev) => ({
        ...prev,
        length: Alert.valBetweenPoint1To300,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        length: "",
      }));
    }

    // Width error
    if (product.width === null) {
      setErrors((prev) => ({
        ...prev,
        width: Alert.alertForWidth,
      }));
      errorHas = true;
    } else if (Number(product.width < 1 || product.width > 300)) {
      setErrors((prev) => ({
        ...prev,
        width: Alert.alertForMaxWidth,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({ ...prev, width: "" }));
    }

    // height error
    if (product.height === null) {
      setErrors((prev) => ({
        ...prev,
        height: Alert.alertForHeight,
      }));
      errorHas = true;
    } else if (Number(product.height < 1 || product.height > 300)) {
      setErrors((prev) => ({
        ...prev,
        height: Alert.alertForMaxHeight,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({ ...prev, height: "" }));
    }

    // weight error
    if (product.weight === null) {
      setErrors((prev) => ({
        ...prev,
        weight: Alert.alertForWeight,
      }));
      errorHas = true;
    } else if (
      weightUnit === "kg" &&
      product.weight &&
      (product.weight < 0.1 || product.weight > 20)
    ) {
      setErrors((prev) => ({
        ...prev,
        weight: Alert.alertForMaxWeightKG,
      }));
      errorHas = true;
    } else if (
      weightUnit === "gm" &&
      (product.weight < 100 || product.weight > 20000)
    ) {
      setErrors((prev) => ({
        ...prev,
        weight: Alert.alertForMaxWeightGM,
      }));
      errorHas = true;
    } else {
      setErrors((prev) => ({ ...prev, weight: "" }));
    }

    if (errorHas) {
      setShippingCostLoading(false);
      return;
    }

    let productWeight = "";
    if (weightUnit === "gm") {
      productWeight = convertWeightGmToKg(product.weight);
    } else {
      productWeight = product.weight;
    }

    const payload = {
      weight: productWeight,
      height: product.height,
      length: product.length,
      width: product.width,
      pincode: shippingCostCustomPincode,
    };

    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellersProductEstimatedShippingCostApi}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    const res = await response.json();
    if (res.status === 200) {
      setEstimatedShippingCost(res.data);
    } else if (res.status === 400) {
      const errors = res.errors;
      let errorMessage = "";
      if (errors) {
        Object.keys(errors).forEach((key) => {
          errors[key].forEach((message) => {
            errorMessage = errorMessage + " " + message;
          });
        });
      }
      setErrors((prev) => ({
        ...prev,
        estimated_shipping_cost: errorMessage,
      }));
    } else {
      console.error(res);
      setErrors((prev) => ({
        ...prev,
        estimated_shipping_cost: res.message,
      }));
    }
    setShippingCostLoading(false);
  };
  const handleCheckboxChangeShippingCost = () => {
    setIsCheckedShippingCost(!isCheckedShippingCost);

    // reset fields
    setSelectedValue("");
    setShippingCostLoading(false);
    setEstimatedShippingCost("");
    setShippingCostCustomPincode("");
    setEnableCustomPincode(false);

    setErrors((prev) => ({
      ...prev,
      estimated_shipping_cost: "",
    }));
  };

  useEffect(() => {
    if (shippingCostCustomPincode.length === 6) {
      setShippingCostLoading(true);
      getEstimatedShippingCost();
    }
  }, [shippingCostCustomPincode]);

  // pincode dropdown option
  const handleChangeShippingCost = (e) => {
    setShippingCostCustomPincode("");
    setEstimatedShippingCost("");
    setErrors((prev) => ({
      ...prev,
      estimated_shipping_cost: "",
    }));
    const val = e.target.value;
    setSelectedValue(val);
    if (val === "custom") setEnableCustomPincode(true);
    else if (val !== "" && val !== "custom") {
      setShippingCostCustomPincode(val);
      setEnableCustomPincode(false);
    } else setEnableCustomPincode(false);
  };

  // generate slug URL
  const getSlugURL = (productName) => {
    if (productName) {
      let slug = productName.replace(/[^a-zA-Z0-9]+/g, "-").toLowerCase();
      return slug.replace(/-+/g, "-").replace(/^-|-$/g, "");
    }
  };

  // Remove html tags
  const removeHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  // Check if any field has been changed
  useEffect(() => {
    const isDataChanged = Object.values(product).some(
      (value) => value !== null
    );
    setDataChanged(isDataChanged);
  }, [product]);

  // image upload functions start here
  const handleImageUpload = (imgName, name, errorName, index, stock) => {
    const ele = document.getElementById(imgName);
    ele.addEventListener("change", (event) => {
      const file = event.target.files[0];

      if (file === undefined) return;

      // Check file extension
      const allowedExtensions = ["png", "jpg", "jpeg", "webp"];
      const extension = file.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(extension)) {
        updateVariantField(
          index,
          errorName,
          "Please upload an image with .png, .jpg, .jpeg, or .webp extension."
        );
        resetInputType(imgName);
        return;
      }

      // Compress and resize image
      compressAndResizeImage(file, imgName, name, errorName, index, stock);
    });
  };

  const compressAndResizeImage = (
    file,
    imgName,
    name,
    errorName,
    index,
    stock_id
  ) => {
    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Set dimensions to 600x600
        canvas.width = 600;
        canvas.height = 600;

        // Calculate scaling factors to fit image within 600x600
        const scaleFactor = Math.min(600 / img.width, 600 / img.height);
        const newWidth = img.width * scaleFactor;
        const newHeight = img.height * scaleFactor;

        // Draw image on canvas with new dimensions
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        canvas.toBlob(
          (blob) => {
            // Check compressed image size
            if (blob.size > maxSizeInBytes) {
              setErrors((prev) => ({
                ...prev,
                [imgName]: "Image size must be up to 2 MB after compression.",
              }));
              resetInputType(imgName);
              return;
            }

            // Display compressed image
            const compressedImg = new File([blob], file.name, {
              type: file.type,
            });
            const reader = new FileReader();
            reader.onload = () => {
              setImgContainer((prev) => ({
                ...prev,
                [imgName]: reader.result,
              }));
            };
            reader.readAsDataURL(compressedImg);
            updateVariantField(index, name, compressedImg);
            updateVariantField(index, errorName, "");
            if (props.page == "edit") {
              if (stock_id !== null) {
                updateVariantField(index, "update_stock", true);
              } else {
                updateVariantField(index, "new_stock", true);
              }
            }
          },
          file.type,
          0.7 // Compression quality (0.7 means 70% quality)
        );
      };
    };

    reader.readAsDataURL(file);
  };

  function replaceSubstringIfExists(
    parentString,
    substringToFind,
    newSubstring
  ) {
    if (parentString.includes(substringToFind)) {
      return parentString.replace(
        substringToFind,
        newSubstring.split("_").join(" ")
      );
    }

    return parentString;
  }
  const handleError = (key, message) => {
    let keyToBeSplit = key.includes(".");

    if (keyToBeSplit) {
      let splittedKey = key.split(".");

      let stock_id = splittedKey[0] == "update_stock" ? splittedKey[1] : null;

      let errorMessage = replaceSubstringIfExists(message, key, splittedKey[2]);

      let errorMessageField = "";

      if (splittedKey[2] == "variant_id") {
        errorMessageField = "selectedVariant_error";
      } else if (splittedKey[2] == "variant_value_id") {
        errorMessageField = "variant_values_error";
      } else if (splittedKey[2] == "quantity") {
        errorMessageField = "quantity_error";
      } else if (splittedKey[2] == "product_price") {
        errorMessageField = "product_price_error";
      } else if (splittedKey[2] == "current_market_price") {
        errorMessageField = "current_market_price_error";
      } else if (splittedKey[2] == "video_url") {
        errorMessageField = "video_url_error";
      } else if (splittedKey[2] == "sku") {
        errorMessageField = "sku_error";
      } else if (splittedKey[2] == "hns") {
        errorMessageField = "hsn_error";
      } else if (splittedKey[2] == "barcode") {
        errorMessageField = "barcode_error";
      } else if (splittedKey[2] == "thumb_image") {
        errorMessageField = "thumb_image_error";
      } else if (splittedKey[2] == "front_image") {
        errorMessageField = "front_image_error";
      } else if (splittedKey[2] == "left_image") {
        errorMessageField = "left_image_error";
      } else if (splittedKey[2] == "right_image") {
        errorMessageField = "right_image_error";
      } else if (splittedKey[2] == "back_image") {
        errorMessageField = "back_image_error";
      } else if (splittedKey[2] == "top_image") {
        errorMessageField = "top_image_error";
      }

      //

      let indexToUpdate = null;

      if (stock_id) {
        indexToUpdate = variantsProductList.findIndex(
          (item) => item.stock_id == stock_id
        );
      } else {
        let targetIndex = 0;
        variantsProductList.map((product, index) => {
          if (product.stock_id == null) {
            if (targetIndex == splittedKey[1]) {
              return updateVariantField(index, errorMessageField, errorMessage);
            }
            targetIndex++;
          }
        });
      }

      updateVariantField(indexToUpdate, errorMessageField, errorMessage);
    } else {
      showToast("error", message);
      // setIsLoading(false)
    }
  };
  // update product API call
  const updateProductFunction = async (formData) => {
    setIsLoading(true);
    formData.append("_method", "PUT");
    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellersProductsApi}/${productId}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
    const res = await response.json();
    if (res.status === 200) {
      contextObj.setInput("navigation", "true");
      contextObj.setInput("navigationFrom", "UpdateProduct");
      navigate(`${navigation.products}`);
    } else if (res.status === 400 && res.message === "The sku must be unique") {
      setErrors((prev) => ({
        ...prev,
        sku: "The sku must be unique",
      }));
    } else if (res.status === 400) {
      const errors = res.errors;
      const message = res.message;

      if (message) {
        showToast("error", res.message);
        setIsLoading(false);
        return;
      }
      if (errors) {
        Object.keys(errors).forEach((key) => {
          errors[key].forEach((message) => {
            handleError(key, message);
          });
        });
      }
    } else if (res.status === 404) {
      showToast("error", res.message);
    }
    setIsLoading(false);
  };
  const markDefaultProduct = (selectedIndex) => {
    variantsProductList &&
      variantsProductList.map((variantsProduct, index) => {
        updateVariantField(index, "is_default", selectedIndex == index ? 1 : 0);
      });
  };
  const deleteStock = async (stock_id) => {
    const response = await fetch(
      `${API_HOST}/${ApiEndpoint.SellersProductsDeleteStockApi}/${stock_id}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const res = await response.json();
    if (res.status === 200) {
      showToast("success", res.message);
      setVariantsProductList((prevVariants) =>
        prevVariants.filter((_, i) => i !== showRemoveConfirmation.variantIndex)
      );
      cancelRemoveVariant();
    } else if (res.message) {
      showToast("error", res.message);
    }

    setShowRemoveConfirmation((prev) => ({
      loading: false,
    }));
  };

  // create product API call
  const createProductFunction = async (formData) => {
    try {
      setIsLoading(true);

      const response = await fetch(`${API_HOST}/${ApiEndpoint.createProduct}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const res = await response.json();

      if (res.status === 201) {
        showToast("success", Alert.alertForAddingProduct);
        setProduct({
          name: null,
          description: editorContent,
          thumb_image: null,
          front_image: null,
          left_image: null,
          right_image: null,
          back_image: null,
          top_image: null,
          video_url: null,
          product_price: null,
          current_market_price: null,
          sku: null,
          barcode: null,
          weight: null,
          length: null,
          width: null,
          height: null,
          is_donatable: 0,
          is_negotiable: 0,
          status: "Active",
          condition: null,
          age_group_id: null,
          brand_id: null,
          category_id: null,
          is_denied: null,
          denied_note: null,
        });

        setTimeout(() => {
          navigate(`${navigation.products}`);
        }, 1000);
      } else if (res.status === 400) {
        const errors = res.errors;
        let errorMessage = "";

        if (res.message) {
          errorMessage = res.message;
        }

        if (errors) {
          Object.keys(errors).forEach((key) => {
            errors[key].forEach((message) => {
              errorMessage = message;
              handleError(key, message);
            });
          });
        }

        if (errorMessage.includes("sku")) {
          setErrors((prev) => ({
            ...prev,
            sku: errorMessage,
          }));
        } else {
          showToast("error", errorMessage);
        }
      } else if (res.status === 500) {
        showToast("error", Alert.alertForInternalServerError);
        window.location.href = navigation.error;
      } else {
        showToast("error", res.message);
      }
    } catch (error) {
      console.error("An error occurred while creating the product:", error);
      showToast("error", "An unexpected error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const addFirstVariantWithError = () => {
    return new Promise(async (resolve) => {
      await addNewVariant();
      updateVariantField(0, "product_price_error", Alert.alertForPrice);
      updateVariantField(
        0,
        "current_market_price_error",
        Alert.alertForOriginalPrice
      );
      updateVariantField(0, "thumb_image_error", Alert.alertForThumbnail);
      updateVariantField(0, "front_image_error", Alert.alertForFontImage);
      updateVariantField(0, "left_image_error", Alert.alertForLeftImage);
      updateVariantField(0, "right_image_error", Alert.alertForRightImge);
      updateVariantField(0, "back_image_error", Alert.alertForBackImage);
      updateVariantField(0, "top_image_error", Alert.alertForTopImage);
      updateVariantField(0, "quantity_error", Alert.alertForQuantity);
      updateVariantField(0, "sku_error", Alert.alertForSKU);

      // Resolve the promise once all the updates are done
      resolve();
    });
  };

  const productDataHandleSubmit = async (e) => {
    e.preventDefault();
    let hasError = false;
    if (variantsProductList.length == 0) {
      await addFirstVariantWithError();
      hasError = true;
    }

    setProduct((prev) => ({
      ...prev,
      description: removeEmptyHTMLTags(product.description),
    }));

    // name validation
    if (product.name === "" || product.name === null) {
      setErrors((prev) => ({
        ...prev,
        name: Alert.alertForName,
      }));
      hasError = true;
    } else if (product.name.length < 3 || product.name.length > 150) {
      setErrors((prev) => ({
        ...prev,
        name: Alert.alertForNameLength,
      }));
      hasError = true;
    } else {
      setErrors((prev) => ({ ...prev, name: "" }));
    }

    // Description validation
    const emptyHTMLTagsFound = (input) => {
      if (
        /^<\w+><br><\/\w+>/.test(input) ||
        /<\w+><br><\/\w+>$/.test(input) ||
        /^<\w+>\s*<\/\w+>/.test(input)
      ) {
        return true;
      }
      return false;
    };

    if (
      product.status === "Active" &&
      (product.description === null ||
        product.description === "" ||
        product.description === "<p><br></p>")
    ) {
      setErrors((prev) => ({
        ...prev,
        description: Alert.alertForDescription,
      }));
      hasError = true;
    } else if (emptyHTMLTagsFound(product.description)) {
      setErrors((prev) => ({
        ...prev,
        description: "Leading / trailing whitespace not allowed",
      }));
      hasError = true;
    } else {
      setErrors((prev) => ({ ...prev, description: "" }));
    }
    // variant validation
    if (product.status === "Active") {
      variantsProductList &&
        variantsProductList.forEach((product, index) => {
          // product_price validation
          if (
            product.product_price == null ||
            product.product_price == ""
          ) {
            updateVariantField(
              index,
              "product_price_error",
              Alert.alertForPrice
            );
            hasError = true;
          } else if (
            product.product_price !== null &&
            product.product_price !== "" &&
            Number(product.product_price) > 100000
          ) {
            updateVariantField(
              index,
              "product_price_error",
              Alert.alertForPriceMaxValue
            );
            hasError = true;
          } else if (
            product.product_price !== null &&
            product.product_price !== "" &&
            Number(product.product_price) < 100
          ) {
            updateVariantField(
              index,
              "product_price_error",
              Alert.alertForPriceMinValue
            );
            hasError = true;
          } else {
            updateVariantField(index, "product_price_error", "");
          }

          // product current_market_price validation
          if (
            product.current_market_price == null ||
            product.current_market_price == ""
          ) {
            updateVariantField(
              index,
              "current_market_price_error",
              Alert.alertForOriginalPrice
            );
            hasError = true;
          } else if (
            product.current_market_price !== null &&
            product.current_market_price !== "" &&
            product.current_market_price > 200000
          ) {
            updateVariantField(
              index,
              "current_market_price_error",
              Alert.alertForPriceMaxForOrigionalProduct
            );
            hasError = true;
          } else if (
            product.current_market_price !== null &&
            product.current_market_price !== "" &&
            product.current_market_price < 200
          ) {
            updateVariantField(
              index,
              "current_market_price_error",
              Alert.alertForPriceMinForOrigionalProduct
            );
            hasError = true;
          } else {
            updateVariantField(index, "current_market_price_error", "");
          }

          // all images validation
          if (
            product.thumb_image === "null" ||
            product.thumb_image === null ||
            product.thumb_image === undefined
          ) {
            updateVariantField(
              index,
              "thumb_image_error",
              Alert.alertForThumbnail
            );
            hasError = true;
          } else {
            updateVariantField(index, "thumb_image_error", "");
          }
          if (
            product.front_image === "null" ||
            product.front_image === null ||
            product.front_image === undefined
          ) {
            updateVariantField(
              index,
              "front_image_error",
              Alert.alertForFontImage
            );
            hasError = true;
          } else {
            updateVariantField(index, "front_image_error", "");
          }
          if (
            product.left_image === "null" ||
            product.left_image === null ||
            product.left_image === undefined
          ) {
            updateVariantField(
              index,
              "left_image_error",
              Alert.alertForLeftImage
            );
            hasError = true;
          } else {
            updateVariantField(index, "left_image_error", "");
          }
          if (
            product.right_image === "null" ||
            product.right_image === null ||
            product.right_image === undefined
          ) {
            updateVariantField(
              index,
              "right_image_error",
              Alert.alertForRightImge
            );
            hasError = true;
          } else {
            updateVariantField(index, "right_image_error", "");
          }
          if (
            product.back_image === "null" ||
            product.back_image === null ||
            product.back_image === undefined
          ) {
            updateVariantField(
              index,
              "back_image_error",
              Alert.alertForBackImage
            );
            hasError = true;
          } else {
            updateVariantField(index, "back_image_error", "");
          }
          if (
            product.top_image === "null" ||
            product.top_image === null ||
            product.top_image === undefined
          ) {
            updateVariantField(
              index,
              "top_image_error",
              Alert.alertForTopImage
            );
            hasError = true;
          } else {
            updateVariantField(index, "top_image_error", "");
          }
          if (variantsProductList.length > 1) {
            if (
              product.selectedVariant == null ||
              product.selectedVariant == undefined
            ) {
              updateVariantField(
                index,
                "selectedVariant_error",
                "Variant is required field."
              );
              hasError = true;
            } else {
              updateVariantField(index, "selectedVariant_error", "");
            }

            // variant value id
            if (
              product.variant_values == null ||
              product.variant_values == undefined
            ) {
              updateVariantField(
                index,
                "variant_values_error",
                "Variant value is required field."
              );
              hasError = true;
            } else {
              updateVariantField(index, "variant_values_error", "");
            }
          } else {
            updateVariantField(index, "selectedVariant_error", "");
            updateVariantField(index, "variant_values_error", "");
          }
          //

          if (product.quantity == null || product.quantity == undefined) {
            updateVariantField(
              index,
              "quantity_error",
              Alert.alertForQuantity
            );
            hasError = true;
          } else {
            updateVariantField(index, "quantity_error", "");
          }

          if (product.sku == null || product.sku == undefined) {
            updateVariantField(index, "sku_error", Alert.alertForSKU);
            hasError = true;
          } else {
            updateVariantField(index, "sku_error", "");
          }

          let validVideo = isValidVideoLink(product.video_url);
          if (product.status === "Active" && validVideo === false) {
            updateVariantField(
              index,
              "video_url_error",
              "Alert.alertForVideoUrl"
            );
            hasError = true;
          } else {
            updateVariantField(index, "video_url_error", "");
          }
        });
    }    
    if (
      product.status === "Active" &&
      (product.weight === null || product.weight === "")
    ) {
      setErrors((prev) => ({
        ...prev,
        weight: Alert.alertForWeight,
      }));
      hasError = true;
    } else if (
      product.weight !== null &&
      product.weight !== "" &&
      weightUnit === "kg" &&
      (product.weight < 0.1 || product.weight > 20)
    ) {
      setErrors((prev) => ({
        ...prev,
        weight: Alert.alertForMaxWeightKG,
      }));
      hasError = true;
    } else if (
      product.weight !== null &&
      product.weight !== "" &&
      weightUnit === "gm" &&
      (product.weight < 100 || product.weight > 20000)
    ) {
      setErrors((prev) => ({
        ...prev,
        weight: Alert.alertForMaxWeightGM,
      }));
      hasError = true;
    } else {
      setErrors((prev) => ({ ...prev, weight: "" }));
    }
    let productConvertedWeight = "";
    if (weightUnit === "gm") {
      productConvertedWeight = convertWeightGmToKg(product.weight);
    } else {
      productConvertedWeight = product.weight;
    }

    // Product length validation
    if (
      product.status === "Active" &&
      (product.length === null || product.length === "")
    ) {
      setErrors((prev) => ({
        ...prev,
        length: Alert.alertForLength,
      }));
      hasError = true;
    } else if (
      product.length != "" &&
      product.length != null &&
      !isValidNumeric(product.length)
    ) {
      setErrors((prev) => ({
        ...prev,
        length: Alert.invalidFormat,
      }));
      hasError = true;
    } else if (
      product.length != "" &&
      product.length != null &&
      (product.length === 0 ||
        product.length === "0" ||
        product.length < 0.1 ||
        product.length > 300)
    ) {
      setErrors((prev) => ({
        ...prev,
        length: Alert.valBetweenPoint1To300,
      }));
      hasError = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        length: "",
      }));
    }

    if (
      product.status === "Active" &&
      (product.width === null || product.width === "")
    ) {
      setErrors((prev) => ({
        ...prev,
        width: Alert.alertForWidth,
      }));
      hasError = true;
    } else if (
      product.width != "" &&
      product.width != null &&
      !isValidNumeric(product.width)
    ) {
      setErrors((prev) => ({
        ...prev,
        width: Alert.invalidFormat,
      }));
      hasError = true;
    } else if (
      product.width != "" &&
      product.width != null &&
      !isValidNumeric &&
      (product.width === 0 ||
        product.width === "0" ||
        product.width < 0.1 ||
        product.width > 300)
    ) {
      setErrors((prev) => ({
        ...prev,
        width: Alert.valBetweenPoint1To300,
      }));
      hasError = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        width: "",
      }));
    }

    if (
      product.status === "Active" &&
      (product.height === null || product.height === "")
    ) {
      setErrors((prev) => ({
        ...prev,
        height: Alert.alertForHeight,
      }));
      hasError = true;
    } else if (
      product.height != "" &&
      product.height != null &&
      !isValidNumeric(product.height)
    ) {
      setErrors((prev) => ({
        ...prev,
        height: Alert.invalidFormat,
      }));
      hasError = true;
    } else if (
      product.height != "" &&
      product.height != null &&
      (product.height === 0 ||
        product.height === "0" ||
        product.height < 0.1 ||
        product.height > 300)
    ) {
      setErrors((prev) => ({
        ...prev,
        height: Alert.valBetweenPoint1To300,
      }));
      hasError = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        height: "",
      }));
    }

    // product condition validation
    if (
      product.status === "Active" &&
      (product.condition === null || product.condition === "Select")
    ) {
      setErrors((prev) => ({
        ...prev,
        condition: Alert.alertForCondition,
      }));
      hasError = true;
    } else {
      setErrors((prev) => ({ ...prev, condition: "" }));
    }

    // Applicable age group validation
    if (
      product.status === "Active" &&
      (product.age_group_id === null || product.age_group_id === "Select")
    ) {
      setErrors((prev) => ({
        ...prev,
        age_group_id: Alert.alertForAge,
      }));
      hasError = true;
    } else {
      setErrors((prev) => ({ ...prev, age_group_id: "" }));
    }

    // product category validation
    if (
      product.status === "Active" &&
      (product.category_id === null || product.category_id === "Select")
    ) {
      setErrors((prev) => ({
        ...prev,
        category_id: Alert.alertForCategory,
      }));
      hasError = true;
    } else {
      setErrors((prev) => ({ ...prev, category_id: "" }));
    }

    // if any field has error, break the function call from here
    if (hasError) return;

    // mandatory fields for update and create Product
    const formData = new FormData();
    formData.append("status", product.status);
    formData.append("name", product.name);

    // optional fields
    if (product.description) {
      const descriptionWithAmpersand = convertToAmpersand(product.description);
      formData.append("description", descriptionWithAmpersand);
    }

    if (product.length) {
      formData.append("length", product.length);
    }
    if (product.width) {
      formData.append("width", product.width);
    }
    if (product.height) {
      formData.append("height", product.height);
    }
    if (product.weight) {
      formData.append("weight", productConvertedWeight);
    }
    if (product.is_donatable) {
      formData.append("is_donatable", product.is_donatable);
    }
    if (product.is_negotiable) {
      formData.append("is_negotiable", product.is_negotiable);
    }
    if (product.brand_id && product.brand_id !== "Select") {
      formData.append("brand_id", product.brand_id);
    }
    if (product.category_id && product.category_id !== "Select") {
      formData.append("category_id", product.category_id);
    }
    if (product.condition && product.condition !== "Select") {
      formData.append("condition", product.condition);
    }
    if (product.age_group_id && product.age_group_id !== "Select") {
      formData.append("age_group_id", product.age_group_id);
    }

    // Add meta title and description
    if (product.meta_title) {
      formData.append("meta_title", product.meta_title);
    } else {
      formData.append(
        "meta_title",
        product.name.substring(0, metaTitleMaxLength)
      );
    }
    if (product.meta_description) {
      formData.append("meta_description", product.meta_description);
    } else if (product.description) {
      formData.append("meta_description", removeHtmlTags(product.description));
    }

    if (pageName === "Add") {
      let has_variant = 0;
      variantsProductList.forEach((variant, index) => {
        if (variant.selectedVariant) {
          formData.append(
            `stocks[${index}][variant_id]`,
            variant.selectedVariant.id ? variant.selectedVariant.id : ""
          );
          if (variant.variant_values) {
            formData.append(
              `stocks[${index}][variant_value_id]`,
              variant.variant_values.id ? variant.variant_values.id : ""
            );
            has_variant = 1;
          }
        }
        formData.append(`stocks[${index}][quantity]`, variant.quantity);
        formData.append(
          `stocks[${index}][product_price]`,
          variant.product_price ? variant.product_price : ""
        );
        formData.append(
          `stocks[${index}][current_market_price]`,
          variant.current_market_price ? variant.current_market_price : ""
        );

        formData.append(
          `stocks[${index}][video_url]`,
          variant.video_url ? variant.video_url : ""
        );
        formData.append(
          `stocks[${index}][sku]`,
          variant.sku ? variant.sku : ""
        );        
        formData.append(
          `stocks[${index}][barcode]`,
          variant.barcode ? variant.barcode : ""
        );
        formData.append(`stocks[${index}][is_default]`, variant.is_default);
        formData.append(`stocks[${index}][thumb_image]`, variant.thumb_image);
        formData.append(`stocks[${index}][front_image]`, variant.front_image);
        formData.append(`stocks[${index}][left_image]`, variant.left_image);
        formData.append(`stocks[${index}][right_image]`, variant.right_image);
        formData.append(`stocks[${index}][back_image]`, variant.back_image);
        formData.append(`stocks[${index}][top_image]`, variant.top_image);
      });

      formData.append("has_variant", has_variant);

      createProductFunction(formData);
    } else if (pageName === "Edit") {
      formData.append("product_update", productUpdate);

      let stock_update = 0;
      let stock_add = 0;
      let title = "";
      let count = 0;

      variantsProductList &&
        variantsProductList.forEach((variant) => {
          if (variant.new_stock == true || variant.update_stock == true) {
            if (variant.new_stock == true && variant.update_stock == false) {
              stock_add = 1;
              title = `new_stock[${count}]`;
            }
            if (variant.update_stock == true && variant.new_stock == false) {
              stock_update = 1;
              title = `update_stock[${variant.stock_id}]`;
            }

            if (variant.selectedVariant) {
              formData.append(
                `${title}[variant_id]`,
                variant.selectedVariant.id ? variant.selectedVariant.id : ""
              );
              if (variant.variant_values) {
                formData.append(
                  `${title}[variant_value_id]`,
                  variant.variant_values.id ? variant.variant_values.id : ""
                );
              }
            }
            formData.append(`${title}[stock_id]`, variant.stock_id);
            formData.append(`${title}[quantity]`, variant.quantity);
            formData.append(
              `${title}[product_price]`,
              variant.product_price ? variant.product_price : ""
            );
            formData.append(
              `${title}[current_market_price]`,
              variant.current_market_price ? variant.current_market_price : ""
            );

            formData.append(
              `${title}[video_url]`,
              variant.video_url ? variant.video_url : ""
            );
            formData.append(`${title}[sku]`, variant.sku ? variant.sku : "");            
            formData.append(
              `${title}[barcode]`,
              variant.barcode ? variant.barcode : ""
            );
            formData.append(`${title}[is_default]`, variant.is_default);
            formData.append(`${title}[thumb_image]`, variant.thumb_image);
            formData.append(`${title}[front_image]`, variant.front_image);
            formData.append(`${title}[left_image]`, variant.left_image);
            formData.append(`${title}[right_image]`, variant.right_image);
            formData.append(`${title}[back_image]`, variant.back_image);
            formData.append(`${title}[top_image]`, variant.top_image);

            if (variant.new_stock == true) {
              count++;
            }
          }
        });

      formData.append("stock_update", stock_update);
      formData.append("stock_add", stock_add);

      updateProductFunction(formData);
    }
  };

  const handleChange = (event) => {
    const { name, checked } = event.target;
    const value = checked ? "1" : "0";
    setProduct((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (
      name === "length" ||
      name === "width" ||
      name === "height" ||
      name === "weight"
    ) {
      // number only validation
      const newValue = value.replace(/[^0-9.]/g, "");
      if (newValue !== "") {
        setIsCheckedShippingCost(false);
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
      setProduct((prev) => ({
        ...prev,
        [name]: newValue,
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
      setProduct((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    if (pageName === "Edit") {
      setProductUpdate(1);
    }
  };
  const handleDescriptionChange = (value) => {
    setErrors((prev) => ({
      ...prev,
      description: "",
      has_error: true,
    }));
    setEditorContent(value);
    setProduct((prev) => ({
      ...prev,
      description: value,
    }));
  };

  // character "e"  not allowed in input types numbers
  const handleKeyPress = (e) => {
    if (e.keyCode === 69 || e.key === "e" || e.key === "+" || e.key === "-") {
      e.preventDefault();
    }
  };

  const checkVariantExist = (variant_index, variant_value) => {
    variantsProductList &&
      variantsProductList.length > 1 &&
      variantsProductList.forEach((variant, index) => {
        if (
          variant_index !== index &&
          variant_value.variant_id == variant.variant_values.variant_id &&
          variant_value.id == variant.variant_values.id
        ) {
          updateVariantField(
            variant_index,
            "variant_values_error",
            "This variant already exists."
          );
        }
      });
  };
  return (
    <div className="container">
      {showRemoveConfirmation.modal && (
        <div className="modal fade">
          <div className="modal-content">
            <div className="modal-header">
              <p className="modal-heading">Remove Variant</p>
              <button
                type="button"
                className="order modal-close-icon"
                onClick={cancelRemoveVariant}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to remove variant?</p>
            </div>

            <div className="modal-buttons">
              <button
                type="button"
                className="btn secondary-btn"
                onClick={cancelRemoveVariant}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn primary-btn"
                onClick={() => {
                  filterVariant(showRemoveConfirmation.variantIndex);
                }}
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="container-heading">
        <h2
          className="cursor-pointer"
          onClick={() => {
            navigate(`${navigation.products}`);
          }}
        >
          <span>
            <svg
              width="15"
              height="10"
              viewBox="0 0 15 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 4.11623V5.884H3.44772L6.36769 8.75001L5.09417 10L0 5.00003L5.09417 0L6.36769 1.25L3.44751 4.11623H15Z"
                fill="#939393"
              />
            </svg>
          </span>
          {`${pageName} Product`}
        </h2>
      </div>

      <div className="product-container">
        {/* Product rejected note */}
        {product.is_denied === 1 && product.denied_note && (
          <div className="product-denied-note">
            <p>
              <span>Rejection note</span>: {product.denied_note}
            </p>
          </div>
        )}

        {/* <form onSubmit={productDataHandleSubmit}> */}
        <div className="product-input-container">
          <div className="product-left-area">
            {/* name and description div */}
            <div>
              <div className="single-row">
                <label>
                  Title <span className="red"> *</span>
                </label>
                <input
                  id="product-create-update-name"
                  type="text"
                  name="name"
                  value={product.name}
                  onChange={handleInputChange}
                  className="form-control"
                  ref={inputRef}
                />
                <label id="product_name_error" className="red">
                  {errors.name}
                </label>
              </div>
              <div className="single-row">
                <label>
                  Description
                  {product.status === "Active" && (
                    <span id="title_Name-error" className="red">
                      {" "}
                      *
                    </span>
                  )}
                </label>
                <ReactQuill
                  id="product-create-update-description"
                  className="custom-react-quill"
                  name="description"
                  value={product.description}
                  onChange={handleDescriptionChange}
                  theme="snow"
                  style={{ height: "fitContent" }}
                />
                <label id="product_description_error" className="red">
                  {errors.description}
                </label>
              </div>
            </div>

            <div className="addProdVariantContainer">
              {variantsProductList &&
                variantsProductList.map((product, index) => {
                  return (
                    <div>
                      <div className="addProdVariantCheckboxContainer">
                        <div className="">
                          <label className="checkbox-label">
                            <input
                              checked={product.is_default == 1}
                              type="checkbox"
                              id="product-price-first"
                              name="priceOption"
                              value="product_price_as_first"
                              onClick={() => {
                                updateVariantField(
                                  index,
                                  product.stock_id == null
                                    ? "new_stock"
                                    : "update_stock",
                                  true
                                );

                                markDefaultProduct(index);
                              }}
                            />
                            Show This Variant In Product List Page
                          </label>
                        </div>
                        {index !== 0 ? (
                          <div className="prodVariantCheckbox">
                            <label className="checkbox-label">
                              <input
                                checked={product.product_price_as_first}
                                type="checkbox"
                                id="product-price-first"
                                name="priceOption"
                                value="product_price_as_first"
                                onClick={() => {
                                  if (product.product_price_as_first == true) {
                                    updateVariantField(
                                      index,
                                      "product_price_as_first",
                                      false
                                    );
                                  } else {
                                    checkFirstVariantPrice(
                                      index,
                                      "product_price_as_first"
                                    );
                                  }
                                }}
                              />
                              Product Price as First
                            </label>

                            <label className="checkbox-label">
                              <input
                                checked={product.current_market_price_as_first}
                                type="checkbox"
                                id="market-price-first"
                                name="priceOption"
                                value="current_market_price_as_first"
                                onClick={() => {
                                  if (
                                    product.current_market_price_as_first ==
                                    true
                                  ) {
                                    updateVariantField(
                                      index,
                                      "current_market_price_as_first",
                                      false
                                    );
                                  } else {
                                    checkFirstVariantPrice(
                                      index,
                                      "current_market_price_as_first"
                                    );
                                  }
                                }}
                              />
                              Current Market Price as First
                            </label>
                          </div>
                        ) : (
                          <div></div>
                        )}

                        <button
                          className="addRemoveProdVariantBtn"
                          onClick={(e) => {
                            if (product.is_default == 0) {
                              setShowRemoveConfirmation((prev) => ({
                                ...prev,
                                modal: true,
                                variantIndex: index,
                                stock_id: product.stock_id,
                              }));
                            } else {
                              if (pageName == "Edit") {
                                showToast(
                                  "error",
                                  `${
                                    variantsProductList.length > 1
                                      ? "Please select another Variant to show in Product List Page before removing this Variant."
                                      : "Only one variant is available."
                                  }`
                                );
                              }
                              e.preventDefault();
                              e.stopPropagation();
                            }
                          }}
                          style={{
                            opacity: product.is_default == 0 ? 1 : 0.5,
                          }}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} /> Remove
                        </button>
                      </div>
                      {/* images and video url div */}
                      <div>
                        <div className="images-instruction-container">
                          <div className="mediaHeadingDiv">
                            <p
                              className="imges-guide"
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                            >
                              Media
                              <span>
                                <svg
                                  width="11"
                                  height="11"
                                  viewBox="0 0 11 11"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M5.5 1.1C4.33305 1.1 3.21389 1.56357 2.38873 2.38873C1.56357 3.21389 1.1 4.33305 1.1 5.5C1.1 6.66695 1.56357 7.78611 2.38873 8.61127C3.21389 9.43643 4.33305 9.9 5.5 9.9C6.66695 9.9 7.78611 9.43643 8.61127 8.61127C9.43643 7.78611 9.9 6.66695 9.9 5.5C9.9 4.33305 9.43643 3.21389 8.61127 2.38873C7.78611 1.56357 6.66695 1.1 5.5 1.1ZM0 5.5C0 2.46235 2.46235 0 5.5 0C8.53765 0 11 2.46235 11 5.5C11 8.53765 8.53765 11 5.5 11C2.46235 11 0 8.53765 0 5.5Z"
                                    fill="#A4A4A4"
                                  />
                                  <path
                                    d="M5.50078 4.4002C5.64665 4.4002 5.78654 4.45814 5.88969 4.56129C5.99283 4.66443 6.05078 4.80433 6.05078 4.9502V8.2502C6.05078 8.39606 5.99283 8.53596 5.88969 8.6391C5.78654 8.74225 5.64665 8.8002 5.50078 8.8002C5.35491 8.8002 5.21502 8.74225 5.11187 8.6391C5.00873 8.53596 4.95078 8.39606 4.95078 8.2502V4.9502C4.95078 4.80433 5.00873 4.66443 5.11187 4.56129C5.21502 4.45814 5.35491 4.4002 5.50078 4.4002ZM6.32578 3.0252C6.32578 3.244 6.23886 3.45384 6.08414 3.60856C5.92943 3.76328 5.71958 3.8502 5.50078 3.8502C5.28198 3.8502 5.07214 3.76328 4.91742 3.60856C4.7627 3.45384 4.67578 3.244 4.67578 3.0252C4.67578 2.80639 4.7627 2.59655 4.91742 2.44183C5.07214 2.28711 5.28198 2.2002 5.50078 2.2002C5.71958 2.2002 5.92943 2.28711 6.08414 2.44183C6.23886 2.59655 6.32578 2.80639 6.32578 3.0252Z"
                                    fill="#A4A4A4"
                                  />
                                </svg>
                              </span>
                            </p>
                          </div>
                          {tooltipVisible && (
                            <div className="images-tooltip">
                              <p>
                                * Allowed extensions [.png, .jpg, .jpeg, .webp]
                              </p>
                              <p>* Thumbnail must be 1:1 ratio</p>
                              <p>* Size upto 2 MB</p>
                            </div>
                          )}

                          {/* logic for image uploading starts from here */}
                          <div className="product-images">
                            <div className="thumb-image">
                              <label>
                                Thumbnail
                                {product.status === "Active" && (
                                  <span className="red"> *</span>
                                )}
                              </label>
                              <div
                                onClick={(e) => {
                                  if (!product.thumb_image) {
                                    handleImageUpload(
                                      `${index}_thumb_image`,
                                      "thumb_image",
                                      "thumb_image_error",
                                      index,
                                      product.stock_id
                                    );
                                  }
                                }}
                                id={`thumb_imageUploaded_For_Sellers_${index}`}
                                className={
                                  "image-dropzone " +
                                  (product.thumb_image && "dropzone-uploaded")
                                }
                              >
                                {product.thumb_image ? (
                                  <>
                                    <img
                                      className="product-image"
                                      src={
                                        product.thumb_image instanceof File
                                          ? URL.createObjectURL(
                                              product.thumb_image
                                            )
                                          : `${IMG_HOST}/${product.thumb_image}`
                                      }
                                      loading="lazy"
                                    />
                                    <div className="img-btn-action">
                                      <div
                                        className="img-btns"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          e.preventDefault();
                                        }}
                                        style={{ zIndex: 1 }}
                                      >
                                        {typeof product.thumb_image ==
                                          "object" && (
                                          <button
                                            type="button"
                                            className="image-edit-icon"
                                            onClick={(e) => {
                                              loadImgOnModal(
                                                "thumb_image",
                                                product.thumb_image
                                              );
                                              setProdImageToEdit((prev) => ({
                                                ...prev,
                                                imgFile: product.thumb_image,
                                                imgName: "thumb_image",
                                                imgNameError:
                                                  product.thumb_image_error,
                                                index: index,
                                              }));
                                            }}
                                          >
                                            <FontAwesomeIcon icon={faEdit} />
                                            <span>Edit</span>
                                          </button>
                                        )}
                                        <button
                                          type="button"
                                          className="image-remove-icon"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();

                                            updateVariantField(
                                              index,
                                              "thumb_image",
                                              null
                                            );
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faTrashAlt} />
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <p style={{ position: "absolute" }}>
                                      Click to upload
                                    </p>
                                    <input
                                      key={`${index}_thumb_image`}
                                      id={`${index}_thumb_image`}
                                      type="file"
                                      accept=".png, .jpg, .jpeg, .webp"
                                      name="thumb_image"
                                      onChange={(e) => {
                                        updateVariantField(
                                          index,
                                          "thumb_image",
                                          e.target.files[0]
                                        );
                                        updateVariantField(
                                          index,
                                          "thumb_image_error",
                                          ""
                                        );
                                      }}
                                      style={{
                                        opacity: 0,
                                        cursor: "pointer",
                                        height: "100%",
                                      }}
                                    />
                                  </>
                                )}
                              </div>
                              <label
                                id={`product_thumb_image_error_${index}`}
                                className="red"
                              >
                                {product.thumb_image_error}
                              </label>
                            </div>
                            <div>
                              <label>
                                Front View
                                {product.status === "Active" && (
                                  <span className="red"> *</span>
                                )}
                              </label>
                              <div
                                onClick={(e) => {
                                  if (!product.front_image) {
                                    handleImageUpload(
                                      `${index}_front_image`,
                                      "front_image",
                                      "front_image_error",
                                      index,
                                      product.stock_id
                                    );
                                  }
                                }}
                                id={`font_imageUploaded_For_Sellers_${index}`}
                                className={
                                  "image-dropzone " +
                                  (product.front_image && "dropzone-uploaded")
                                }
                              >
                                {product.front_image ? (
                                  <>
                                    <img
                                      className="product-image"
                                      src={
                                        product.front_image instanceof File
                                          ? URL.createObjectURL(
                                              product.front_image
                                            )
                                          : `${IMG_HOST}/${product.front_image}`
                                      }
                                      loading="lazy"
                                    />
                                    <div className="img-btn-action">
                                      <div
                                        className="img-btns"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          e.preventDefault();
                                        }}
                                        style={{ zIndex: 1 }}
                                      >
                                        {typeof product.front_image ==
                                          "object" && (
                                          <button
                                            type="button"
                                            className="image-edit-icon"
                                            onClick={() => {
                                              loadImgOnModal(
                                                "front_image",
                                                product.front_image
                                              );
                                              setProdImageToEdit((prev) => ({
                                                ...prev,
                                                imgFile: product.front_image,
                                                imgName: "front_image",
                                                imgNameError:
                                                  product.front_image_error,
                                                index: index,
                                              }));
                                            }}
                                          >
                                            <FontAwesomeIcon icon={faEdit} />
                                            <span>Edit</span>
                                          </button>
                                        )}
                                        <button
                                          type="button"
                                          className="image-remove-icon"
                                          onClick={(e) => {
                                            e.stopPropagation();

                                            updateVariantField(
                                              index,
                                              "front_image",
                                              null
                                            );
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faTrashAlt} />
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <p style={{ position: "absolute" }}>
                                      Click to upload
                                    </p>
                                    <input
                                      key={`${index}_front_image`}
                                      id={`${index}_front_image`}
                                      type="file"
                                      accept=".png, .jpg, .jpeg, .webp"
                                      name="front_image"
                                      onChange={(e) => {
                                        updateVariantField(
                                          index,
                                          "front_image",
                                          e.target.files[0]
                                        );
                                        updateVariantField(
                                          index,
                                          "front_image_error",
                                          ""
                                        );
                                      }}
                                      style={{
                                        opacity: 0,
                                        cursor: "pointer",
                                        height: "100%",
                                      }}
                                    />
                                  </>
                                )}
                              </div>
                              <label
                                id={`product_front_image_error_${index}`}
                                className="red"
                              >
                                {product.front_image_error}
                              </label>
                            </div>
                            <div>
                              <label>
                                Left View
                                {product.status === "Active" && (
                                  <span className="red"> *</span>
                                )}
                              </label>
                              <div
                                onClick={(e) => {
                                  if (!product.left_image) {
                                    handleImageUpload(
                                      `${index}_left_image`,
                                      "left_image",
                                      "left_image_error",
                                      index,
                                      product.stock_id
                                    );
                                  }
                                }}
                                id={`left_imageUploaded_For_Sellers_${index}`}
                                className={
                                  "image-dropzone " +
                                  (product.left_image && "dropzone-uploaded")
                                }
                              >
                                {product.left_image ? (
                                  <>
                                    <img
                                      className="product-image"
                                      src={
                                        product.left_image instanceof File
                                          ? URL.createObjectURL(
                                              product.left_image
                                            )
                                          : `${IMG_HOST}/${product.left_image}`
                                      }
                                      loading="lazy"
                                    />
                                    <div className="img-btn-action">
                                      <div
                                        className="img-btns"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          e.preventDefault();
                                        }}
                                        style={{ zIndex: 1 }}
                                      >
                                        {typeof product.left_image ==
                                          "object" && (
                                          <button
                                            type="button"
                                            className="image-edit-icon"
                                            onClick={() => {
                                              loadImgOnModal(
                                                "left_image",
                                                product.left_image
                                              );
                                              setProdImageToEdit((prev) => ({
                                                ...prev,
                                                imgFile: product.left_image,
                                                imgName: "left_image",
                                                imgNameError:
                                                  product.left_image_error,
                                                index: index,
                                              }));
                                            }}
                                          >
                                            <FontAwesomeIcon icon={faEdit} />
                                            <span>Edit</span>
                                          </button>
                                        )}
                                        <button
                                          type="button"
                                          className="image-remove-icon"
                                          onClick={(e) => {
                                            e.stopPropagation();

                                            updateVariantField(
                                              index,
                                              "left_image",
                                              null
                                            );
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faTrashAlt} />
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <p style={{ position: "absolute" }}>
                                      Click to upload
                                    </p>
                                    <input
                                      key={`${index}_left_image`}
                                      id={`${index}_left_image`}
                                      type="file"
                                      accept=".png, .jpg, .jpeg, .webp"
                                      name="left_image"
                                      onChange={(e) => {
                                        updateVariantField(
                                          index,
                                          "left_image",
                                          e.target.files[0]
                                        );
                                        updateVariantField(
                                          index,
                                          "left_image_error",
                                          ""
                                        );
                                      }}
                                      style={{
                                        opacity: 0,
                                        cursor: "pointer",
                                        height: "100%",
                                      }}
                                    />
                                  </>
                                )}
                              </div>
                              <label
                                id={`product_left_image_error_${index}`}
                                className="red"
                              >
                                {product.left_image_error}
                              </label>
                            </div>
                            <div>
                              <label>
                                Right View
                                {product.status === "Active" && (
                                  <span className="red"> *</span>
                                )}
                              </label>
                              <div
                                onClick={(e) => {
                                  if (!product.right_image) {
                                    handleImageUpload(
                                      `${index}_right_image`,
                                      "right_image",
                                      "right_image_error",
                                      index,
                                      product.stock_id
                                    );
                                  }
                                }}
                                id={`right_imageUploaded_For_Sellers_${index}`}
                                className={
                                  "image-dropzone " +
                                  (product.right_image && "dropzone-uploaded")
                                }
                              >
                                {product.right_image ? (
                                  <>
                                    <img
                                      className="product-image"
                                      src={
                                        product.right_image instanceof File
                                          ? URL.createObjectURL(
                                              product.right_image
                                            )
                                          : `${IMG_HOST}/${product.right_image}`
                                      }
                                      loading="lazy"
                                    />
                                    <div className="img-btn-action">
                                      <div
                                        className="img-btns"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          e.preventDefault();
                                        }}
                                        style={{ zIndex: 1 }}
                                      >
                                        {typeof product.right_image ==
                                          "object" && (
                                          <button
                                            type="button"
                                            className="image-edit-icon"
                                            onClick={() => {
                                              loadImgOnModal(
                                                "right_image",
                                                product.right_image
                                              );
                                              setProdImageToEdit((prev) => ({
                                                ...prev,
                                                imgFile: product.right_image,
                                                imgName: "right_image",
                                                imgNameError:
                                                  product.right_image_error,
                                                index: index,
                                              }));
                                            }}
                                          >
                                            <FontAwesomeIcon icon={faEdit} />
                                            <span>Edit</span>
                                          </button>
                                        )}
                                        <button
                                          type="button"
                                          className="image-remove-icon"
                                          onClick={(e) => {
                                            e.stopPropagation();

                                            updateVariantField(
                                              index,
                                              "right_image",
                                              null
                                            );
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faTrashAlt} />
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <p style={{ position: "absolute" }}>
                                      Click to upload
                                    </p>{" "}
                                    <input
                                      key={`${index}_right_image`}
                                      id={`${index}_right_image`}
                                      type="file"
                                      accept=".png, .jpg, .jpeg, .webp"
                                      name="right_image"
                                      onChange={(e) => {
                                        updateVariantField(
                                          index,
                                          "right_image",
                                          e.target.files[0]
                                        );
                                        updateVariantField(
                                          index,
                                          "right_image_error",
                                          ""
                                        );
                                      }}
                                      style={{
                                        opacity: 0,
                                        cursor: "pointer",
                                        height: "100%",
                                      }}
                                    />
                                  </>
                                )}
                              </div>
                              <label
                                id={`product_right_image_error_${index}`}
                                className="red"
                              >
                                {product.right_image_error}
                              </label>
                            </div>
                            <div>
                              <label>
                                Back View
                                {product.status === "Active" && (
                                  <span className="red"> *</span>
                                )}
                              </label>
                              <div
                                onClick={(e) => {
                                  if (!product.back_image) {
                                    handleImageUpload(
                                      `${index}_back_image`,
                                      "back_image",
                                      "back_image_error",
                                      index,
                                      product.stock_id
                                    );
                                  }
                                }}
                                id={`Back_imageUploaded_For_Sellers_${index}`}
                                className={
                                  "image-dropzone " +
                                  (product.back_image && "dropzone-uploaded")
                                }
                              >
                                {product.back_image ? (
                                  <>
                                    <img
                                      className="product-image"
                                      src={
                                        product.back_image instanceof File
                                          ? URL.createObjectURL(
                                              product.back_image
                                            )
                                          : `${IMG_HOST}/${product.back_image}`
                                      }
                                      loading="lazy"
                                    />
                                    <div className="img-btn-action">
                                      <div
                                        className="img-btns"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          e.preventDefault();
                                        }}
                                        style={{ zIndex: 1 }}
                                      >
                                        {typeof product.back_image ==
                                          "object" && (
                                          <button
                                            type="button"
                                            className="image-edit-icon"
                                            onClick={() => {
                                              loadImgOnModal(
                                                "back_image",
                                                product.back_image
                                              );
                                              setProdImageToEdit((prev) => ({
                                                ...prev,
                                                imgFile: product.back_image,
                                                imgName: "back_image",
                                                imgNameError:
                                                  product.back_image_error,
                                                index: index,
                                              }));
                                            }}
                                          >
                                            <FontAwesomeIcon icon={faEdit} />
                                            <span>Edit</span>
                                          </button>
                                        )}
                                        <button
                                          type="button"
                                          className="image-remove-icon"
                                          onClick={(e) => {
                                            e.stopPropagation();

                                            updateVariantField(
                                              index,
                                              "back_image",
                                              null
                                            );
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faTrashAlt} />
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <p style={{ position: "absolute" }}>
                                      Click to upload
                                    </p>{" "}
                                    <input
                                      key={`${index}_back_image`}
                                      id={`${index}_back_image`}
                                      type="file"
                                      accept=".png, .jpg, .jpeg, .webp"
                                      name="back_image"
                                      onChange={(e) => {
                                        updateVariantField(
                                          index,
                                          "back_image",
                                          e.target.files[0]
                                        );
                                        updateVariantField(
                                          index,
                                          "back_image_error",
                                          ""
                                        );
                                      }}
                                      style={{
                                        opacity: 0,
                                        cursor: "pointer",
                                        height: "100%",
                                      }}
                                    />
                                  </>
                                )}
                              </div>
                              <label
                                id={`product_back_image_error_${index}`}
                                className="red"
                              >
                                {product.back_image_error}
                              </label>
                            </div>
                            <div>
                              <label>
                                Top View
                                {product.status === "Active" && (
                                  <span className="red"> *</span>
                                )}
                              </label>
                              <div
                                onClick={(e) => {
                                  if (!product.top_image) {
                                    handleImageUpload(
                                      `${index}_top_image`,
                                      "top_image",
                                      "top_image_error",
                                      index,
                                      product.stock_id
                                    );
                                  }
                                }}
                                id={`top_imageUploaded_For_Sellers_${index}`}
                                className={
                                  "image-dropzone " +
                                  (product.top_image && "dropzone-uploaded")
                                }
                              >
                                {product.top_image ? (
                                  <>
                                    <img
                                      className="product-image"
                                      src={
                                        product.top_image instanceof File
                                          ? URL.createObjectURL(
                                              product.top_image
                                            )
                                          : `${IMG_HOST}/${product.top_image}`
                                      }
                                      loading="lazy"
                                    />
                                    <div className="img-btn-action">
                                      <div
                                        className="img-btns"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          e.preventDefault();
                                        }}
                                        style={{ zIndex: 1 }}
                                      >
                                        {typeof product.top_image ==
                                          "object" && (
                                          <button
                                            type="button"
                                            className="image-edit-icon"
                                            onClick={() => {
                                              loadImgOnModal(
                                                "top_image",
                                                product.top_image
                                              );
                                              setProdImageToEdit((prev) => ({
                                                ...prev,
                                                imgFile: product.top_image,
                                                imgName: "top_image",
                                                imgNameError:
                                                  product.top_image_error,
                                                index: index,
                                              }));
                                            }}
                                          >
                                            <FontAwesomeIcon icon={faEdit} />
                                            <span>Edit</span>
                                          </button>
                                        )}
                                        <button
                                          type="button"
                                          className="image-remove-icon"
                                          onClick={(e) => {
                                            e.stopPropagation();

                                            updateVariantField(
                                              index,
                                              "top_image",
                                              null
                                            );
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faTrashAlt} />
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <p style={{ position: "absolute" }}>
                                      Click to upload
                                    </p>
                                    <input
                                      key={`${index}_top_image`}
                                      id={`${index}_top_image`}
                                      type="file"
                                      accept=".png, .jpg, .jpeg, .webp"
                                      name="top_image"
                                      onChange={(e) => {
                                        updateVariantField(
                                          index,
                                          "top_image",
                                          e.target.files[0]
                                        );
                                        updateVariantField(
                                          index,
                                          "top_image_error",
                                          ""
                                        );
                                      }}
                                      style={{
                                        opacity: 0,
                                        cursor: "pointer",
                                        height: "100%",
                                      }}
                                    />
                                  </>
                                )}
                              </div>
                              <label
                                id={`products_top_image_error_${index}`}
                                className="red"
                              >
                                {product.top_image_error}
                              </label>
                            </div>
                          </div>
                          <div className="single-row">
                            <label>Video URL</label>
                            <input
                              id={`product-create-update-video-url_${index}`}
                              type="text"
                              name="video_url"
                              value={product.video_url}
                              onChange={(e) => {
                                let value = e.target.value;
                                updateVariantField(index, "video_url", value);
                                updateVariantField(
                                  index,
                                  "video_url_error",
                                  ""
                                );
                                if (props.page === "edit") {
                                  if (product.stock_id !== null) {
                                    updateVariantField(
                                      index,
                                      "update_stock",
                                      true
                                    );
                                  } else {
                                    updateVariantField(
                                      index,
                                      "new_stock",
                                      true
                                    );
                                  }
                                }
                              }}
                              className="form-control"
                            />
                            <label
                              id={`product_video_url_error_${index}`}
                              className="red"
                            >
                              {product.video_url_error}
                            </label>
                          </div>
                        </div>
                      </div>

                      {/* HSN */}
                      <div id={`variantDiv_${index}`} className="variantList">
                        <div className="col">
                          <label>
                            Product Price (₹)
                            {product.status === "Active" && (
                              <span className="red"> *</span>
                            )}
                          </label>
                          <div className="product-price">
                            <input
                              id={`product-create-update-product-price_${index}`}
                              type="number"
                              name="product_price"
                              readOnly={product.product_price_as_first}
                              value={product.product_price}
                              onChange={(e) => {
                                let value = e.target.value;
                                if (value > 100000) {
                                  updateVariantField(
                                    index,
                                    "product_price_error",
                                    "Product price must not be greater than 100,000."
                                  );
                                } else {
                                  if (index == 0) {
                                    variantsProductList &&
                                      variantsProductList.forEach(
                                        (prod, index) => {
                                          if (
                                            index == 0 ||
                                            prod.product_price_as_first
                                          ) {
                                            updateVariantField(
                                              index,
                                              "product_price",
                                              value
                                            );
                                            updateVariantField(
                                              index,
                                              "product_price_error",
                                              ""
                                            );
                                          }
                                        }
                                      );
                                  } else {
                                    updateVariantField(
                                      index,
                                      "product_price",
                                      value
                                    );
                                  }
                                  updateVariantField(
                                    index,
                                    "product_price_error",
                                    ""
                                  );
                                }

                                if (props.page === "edit") {
                                  if (product.stock_id !== null) {
                                    updateVariantField(
                                      index,
                                      "update_stock",
                                      true
                                    );
                                  } else {
                                    updateVariantField(
                                      index,
                                      "new_stock",
                                      true
                                    );
                                  }
                                }
                              }}
                              onWheel={handleWheel}
                              className="form-control"
                              min="0"
                              onKeyPress={handleKeyPress}
                            />
                            <span className="question-circle-icon-span">
                              <FontAwesomeIcon
                                className="question-circle-icon"
                                icon={faQuestionCircle}
                              />
                            </span>
                            <p className="product-tooltip">
                              Shipping cost will be deducted from the product
                              price during payment.
                            </p>
                          </div>
                          <label
                            id={`product_price_error_${index}`}
                            className="red"
                          >
                            {product.product_price_error}
                          </label>
                        </div>

                        <div className="col ">
                          <label>
                            Estimated Original Price (₹)
                            {product.status === "Active" && (
                              <span className="red"> *</span>
                            )}
                          </label>
                          <input
                            id={`product-create-update-estimated-original-price_${index}`}
                            type="number"
                            name="current_market_price"
                            readOnly={product.current_market_price_as_first}
                            value={product.current_market_price}
                            onChange={(e) => {
                              let value = e.target.value;
                              if (value > 200000) {
                                updateVariantField(
                                  index,
                                  "current_market_price_error",
                                  "Estimated Original price must not be greater than 200,000."
                                );
                              } else {
                                if (index == 0) {
                                  variantsProductList &&
                                    variantsProductList.forEach(
                                      (prod, index) => {
                                        if (
                                          index == 0 ||
                                          prod.current_market_price_as_first
                                        ) {
                                          updateVariantField(
                                            index,
                                            "current_market_price",
                                            value
                                          );
                                          updateVariantField(
                                            index,
                                            "current_market_price_error",
                                            ""
                                          );
                                        }
                                      }
                                    );
                                } else {
                                  updateVariantField(
                                    index,
                                    "current_market_price",
                                    value
                                  );
                                }
                                updateVariantField(
                                  index,
                                  "current_market_price_error",
                                  ""
                                );
                              }
                              if (props.page === "edit") {
                                if (product.stock_id !== null) {
                                  updateVariantField(
                                    index,
                                    "update_stock",
                                    true
                                  );
                                } else {
                                  updateVariantField(index, "new_stock", true);
                                }
                              }
                            }}
                            onWheel={handleWheel}
                            className="form-control"
                            min="0"
                            onKeyPress={handleKeyPress}
                          />
                          <label
                            id={`current_market_price_error_${index}`}
                            className="red"
                          >
                            {product.current_market_price_error}
                          </label>
                        </div>
                        <div className="col">
                          <div
                            style={{
                              position: "relative",
                            }}
                          >
                            <label>
                              Variant{" "}
                              {product.status === "Publish" && (
                                <span className="red">*</span>
                              )}
                            </label>

                            <div
                              className="form-control"
                              style={{
                                display: "flex",
                              }}
                            >
                              <span
                                className="varientDropDownTitle"
                                onClick={() =>
                                  updateVariantField(
                                    index,
                                    "variantListVisible",
                                    !product.variantListVisible
                                  )
                                }
                              >
                                {" "}
                                <p className="selectedVarientDropDownTitle">
                                  {product.selectedVariant &&
                                  product.selectedVariant.id
                                    ? product.selectedVariant.name
                                    : "Select"}
                                </p>{" "}
                                <FontAwesomeIcon
                                  icon={
                                    product.variantListVisible
                                      ? faSortUp
                                      : faSortDown
                                  }
                                />
                              </span>
                            </div>
                            {product.variantListVisible && (
                              <div className="variantDropdownList">
                                {variantsList.map((variant) => {
                                  return (
                                    <div
                                      className="variantsListOptions"
                                      onClick={async () => {
                                        updateVariantField(
                                          index,
                                          "variantListVisible",
                                          false
                                        );
                                        updateVariantField(
                                          index,
                                          "selectedVariant",
                                          variant
                                        );
                                        updateVariantField(
                                          index,
                                          "variant_values",
                                          []
                                        );
                                        updateVariantField(
                                          index,
                                          "selectedVariant_error",
                                          ""
                                        );
                                        if (
                                          variant.variant_values &&
                                          variant.variant_values.length > 0
                                        ) {
                                          updateVariantField(
                                            index,
                                            "selectedVariant",
                                            variant
                                          );
                                        }

                                        if (props.page === "edit") {
                                          if (product.stock_id !== null) {
                                            updateVariantField(
                                              index,
                                              "update_stock",
                                              true
                                            );
                                          } else {
                                            updateVariantField(
                                              index,
                                              "new_stock",
                                              true
                                            );
                                          }
                                        }
                                      }}
                                    >
                                      <input
                                        type="radio"
                                        checked={
                                          product.selectedVariant &&
                                          product.selectedVariant.id &&
                                          product.selectedVariant.id ==
                                            variant.id
                                        }
                                      />
                                      <label htmlFor="">{variant.name}</label>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                            {product.selectedVariant_error && (
                              <label className="red">
                                {product.selectedVariant_error}
                              </label>
                            )}
                          </div>
                        </div>
                        <div className="col">
                          <div
                            style={{
                              position: "relative",
                            }}
                          >
                            <label>
                              Variant Value{" "}
                              {product.status === "Publish" && (
                                <span className="red">*</span>
                              )}
                            </label>
                            <div
                              className="form-control"
                              style={{
                                display: "flex",
                              }}
                            >
                              <span
                                className="varientDropDownTitle"
                                onClick={() =>
                                  updateVariantField(
                                    index,
                                    "variantValuesListVisible",
                                    !product.variantValuesListVisible
                                  )
                                }
                              >
                                {" "}
                                <p className="selectedVarientDropDownTitle">
                                  {product.variant_values &&
                                  product.variant_values.value
                                    ? product.variant_values.value
                                    : "Select"}
                                </p>{" "}
                                <FontAwesomeIcon
                                  icon={
                                    product.variantValuesListVisible
                                      ? faSortUp
                                      : faSortDown
                                  }
                                />
                              </span>
                            </div>
                            {product.variantValuesListVisible && (
                              <div className="variantDropdownList">
                                {product.selectedVariant ? (
                                  <>
                                    {product.selectedVariant.variant_values &&
                                      product.selectedVariant.variant_values
                                        .length > 0 && (
                                        <>
                                          {product.selectedVariant.variant_values.map(
                                            (variantValue) => {
                                              return (
                                                <div
                                                  className="variantsListOptions"
                                                  onClick={async () => {
                                                    updateVariantField(
                                                      index,
                                                      "variant_values_error",
                                                      ""
                                                    );
                                                    updateVariantField(
                                                      index,
                                                      "variant_values",
                                                      variantValue
                                                    )
                                                      .then(() => {
                                                        updateVariantField(
                                                          index,
                                                          "variantValuesListVisible",
                                                          false
                                                        );
                                                      })
                                                      .then(() => {
                                                        checkVariantExist(
                                                          index,
                                                          variantValue
                                                        );
                                                      });

                                                    if (props.page === "edit") {
                                                      if (
                                                        product.stock_id !==
                                                        null
                                                      ) {
                                                        updateVariantField(
                                                          index,
                                                          "update_stock",
                                                          true
                                                        );
                                                      } else {
                                                        updateVariantField(
                                                          index,
                                                          "new_stock",
                                                          true
                                                        );
                                                      }
                                                    }
                                                  }}
                                                >
                                                  <input
                                                    type="radio"
                                                    checked={
                                                      product.variant_values &&
                                                      product.variant_values
                                                        .id == variantValue.id
                                                    }
                                                  />
                                                  <label htmlFor="">
                                                    {variantValue.value}
                                                  </label>
                                                </div>
                                              );
                                            }
                                          )}
                                        </>
                                      )}
                                  </>
                                ) : (
                                  <label>Please select a variant</label>
                                )}
                              </div>
                            )}

                            {product.variant_values_error && (
                              <label className="red">
                                {product.variant_values_error}
                              </label>
                            )}
                          </div>
                        </div>

                        {/* quantity */}

                        <div className="col ">
                          <label>
                            Quantity
                            {product.status === "Active" && (
                              <span className="red"> *</span>
                            )}
                          </label>
                          <input
                            id="product-create-update-quantity"
                            type="number"
                            name="quantity"
                            value={product.quantity}
                            onChange={(e) => {
                              let value = e.target.value;
                              updateVariantField(index, "quantity", value);
                              updateVariantField(index, "quantity_error", "");

                              if (props.page === "edit") {
                                if (product.stock_id !== null) {
                                  updateVariantField(
                                    index,
                                    "update_stock",
                                    true
                                  );
                                } else {
                                  updateVariantField(index, "new_stock", true);
                                }
                              }
                            }}
                            onWheel={handleWheel}
                            className="form-control"
                            min="0"
                            onKeyPress={handleKeyPress}
                          />
                          <label id="quantity_error" className="red">
                            {product.quantity_error}
                          </label>
                        </div>
                        {/* quantity */}

                        <div className="col">
                          <label>SKU</label>
                          <input
                            id="product-create-update-sku"
                            name="sku"
                            value={product.sku}
                            onChange={(e) => {
                              let value = e.target.value;
                              updateVariantField(index, "sku", value);
                              updateVariantField(index, "sku_error", "");

                              if (props.page === "edit") {
                                if (product.stock_id !== null) {
                                  updateVariantField(
                                    index,
                                    "update_stock",
                                    true
                                  );
                                } else {
                                  updateVariantField(index, "new_stock", true);
                                }
                              }
                            }}
                            type="text"
                            className="form-control"
                          />
                          <label id="sku_error" className="red">
                            {product.sku_error}
                          </label>
                        </div>
                        <div className="col">
                          <label>Barcode (ISBN, UPC, GTIN, etc.)</label>
                          <input
                            id="product-create-update-barcode"
                            name="barcode"
                            value={product.barcode}
                            onChange={(e) => {
                              let value = e.target.value;
                              updateVariantField(index, "barcode", value);
                              updateVariantField(index, "barcode_error", "");
                              if (props.page === "edit") {
                                if (product.stock_id !== null) {
                                  updateVariantField(
                                    index,
                                    "update_stock",
                                    true
                                  );
                                } else {
                                  updateVariantField(index, "new_stock", true);
                                }
                              }
                            }}
                            type="text"
                            className="form-control"
                          />{" "}
                          <label id="barcode_error" className="red">
                            {product.barcode_error}
                          </label>
                        </div>
                      </div>
                    </div>
                  );
                })}

              {(props.page === "new" || props.page === "edit") && (
                <button
                  className="addRemoveProdVariantBtn"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    addNewVariant();
                    if (variantsProductList.length == 1) {
                      updateVariantField(0, "is_default", 1);
                    }
                  }}
                  id={variantsProductList.length == 0 && "addProductBtn"}
                >
                  {variantsProductList.length == 0
                    ? "+ Add Product"
                    : "+ Add Variant"}
                </button>
              )}
            </div>
            {/* shipping details(height, width,length and weight) div */}
            <div id="shipping_details_section">
              <p className="productShippingPara">Shipping</p>
              <div className="select-row ">
                <div className="weightKgDivForMobile">
                  <div className="col" style={{ marginRight: "5px" }}>
                    <label>
                      Weight
                      {product.status === "Active" && (
                        <span className="red"> *</span>
                      )}
                    </label>
                    <input
                      id="product-create-update-weight"
                      type="text"
                      name="weight"
                      value={product.weight}
                      onChange={handleInputChange}
                      onWheel={handleWheel}
                      className="form-control"
                      onKeyPress={handleKeyPress}
                    />
                    <label id="weight_error" className="red">
                      {errors.weight}
                    </label>
                  </div>
                  <select
                    id="product-create-update-weight-type"
                    className="productWeightSelect"
                    value={weightUnit}
                    onChange={weightUnitsChangeHandler}
                  >
                    <option id="sellers-weightType-kilogram" value="kg">
                      kg
                    </option>
                    <option id="sellers-weightType-gram" value="gm">
                      gm
                    </option>
                  </select>
                </div>
                <div className="col">
                  <label>
                    Length (cm)
                    {product.status === "Active" && (
                      <span className="red"> *</span>
                    )}
                  </label>
                  <input
                    id="product-create-update-length"
                    type="text"
                    name="length"
                    value={product.length}
                    onChange={handleInputChange}
                    onWheel={handleWheel}
                    className="form-control"
                    onKeyPress={handleKeyPress}
                    min="0"
                  />
                  <label id="length_error" className="red">
                    {errors.length}
                  </label>
                </div>
                <div className="col ">
                  <label>
                    Width (cm)
                    {product.status === "Active" && (
                      <span className="red"> *</span>
                    )}
                  </label>
                  <input
                    id="product-create-update-width"
                    type="text"
                    name="width"
                    value={product.width}
                    onChange={handleInputChange}
                    onWheel={handleWheel}
                    className="form-control"
                    onKeyPress={handleKeyPress}
                    min="0"
                  />
                  <label id="width_error" className="red">
                    {errors.width}
                  </label>
                </div>
                <div className="col ">
                  <label>
                    Height (cm)
                    {product.status === "Active" && (
                      <span className="red"> *</span>
                    )}
                  </label>
                  <input
                    id="product-create-update-height"
                    type="text"
                    name="height"
                    value={product.height}
                    onChange={handleInputChange}
                    onWheel={handleWheel}
                    className="form-control"
                    onKeyPress={handleKeyPress}
                    min="0"
                  />
                  <label id="height_error" className="red">
                    {errors.height}
                  </label>
                </div>
              </div>
            </div>

            {/* Get estimated shipping cost */}
            <div>
              <div className="barcodeforProductBox">
                <label>
                  <input
                    id="product-create-update-calculate-shipping-cost-checkbox"
                    type="checkbox"
                    checked={isCheckedShippingCost}
                    onClick={handleCheckboxChangeShippingCost}
                  />
                  <span>Calculate estimated shipping cost</span>
                </label>
              </div>
              {isCheckedShippingCost && (
                <>
                  <div className="skuAndBarcodeInputFeild">
                    <div className="col">
                      <label>Pincode</label>
                      <select
                        id="product-create-update-custom-pincode-select"
                        className="form-control editable-select"
                        value={selectedValue}
                        onChange={handleChangeShippingCost}
                      >
                        <option value="">Select</option>
                        {shippingCostPincode && (
                          <option value={shippingCostPincode}>
                            {shippingCostPincode}
                          </option>
                        )}

                        <option value="custom" className="custom-pincode">
                          Custom
                        </option>
                      </select>
                    </div>
                    {enableCustomPincode && (
                      <div className="col">
                        <label htmlFor="">Enter your pincode</label>
                        <input
                          id="product-create-update-pincode"
                          type="text"
                          maxLength="6"
                          className="form-control"
                          onChange={() => {
                            setErrors((prev) => ({
                              ...prev,
                              estimated_shipping_cost: "",
                            }));
                          }}
                          onInput={(e) => {
                            const inputValue = e.target.value;
                            e.target.value = inputValue.replace(/[^0-9]/g, "");
                            setShippingCostCustomPincode(inputValue);
                            setEstimatedShippingCost("");
                          }}
                        />
                      </div>
                    )}
                    {shippingCostLoading ? (
                      <div className="col margin-top-25">
                        <RollingSvg className="shipping-cost-rolling" />
                      </div>
                    ) : (
                      <div className="col">
                        <label>Estimated cost (₹)</label>
                        <input
                          id="product-create-update-shipping-cost"
                          style={{ backgroundColor: "#e5e5e5" }}
                          type="text"
                          className="form-control"
                          disabled
                          value={estimatedShippingCost}
                        />
                      </div>
                    )}
                  </div>
                  {errors.estimated_shipping_cost && (
                    <label id="estimated_shipping_cost_error" className="red">
                      {errors.estimated_shipping_cost}
                    </label>
                  )}
                </>
              )}
            </div>

            {/* give permissions checkbox div  */}
            <div htmlFor="permission checkbox line">
              <div>
                <label
                  className="checkbox-line"
                  style={{ marginBottom: "10px" }}
                >
                  <input
                    id="product-create-update-negotiable-checkbox"
                    type="checkbox"
                    name="is_negotiable"
                    
                    checked={product.is_negotiable === "1"}
                    onChange={handleChange}
                  />{" "}
                  <span>
                    Give permission to the reviewer for adjust the effective
                    product price?
                  </span>
                </label>
              </div>
              <div>
                <label className="checkbox-line">
                  <input
                    id="product-create-update-donatable-checkbox"
                    type="checkbox"
                    name="is_donatable"
                    checked={product.is_donatable === "1"}
                    onChange={handleChange}
                  />{" "}
                  <span>Are you interested to donate this product?</span>
                </label>
              </div>
            </div>

            {/* Product SEO */}
            <div>
              <div className="small-container-heading">
                <p className="productShippingPara">Search Engine Listing</p>
                <button
                  id="blue-edit-btn-for-meta"
                  className="blue-edit-btn"
                  type="button"
                  onClick={() => {
                    setOpenSeoEdit(!openSeoEdit);
                  }}
                >
                  {openSeoEdit ? "Close" : "Edit"}
                </button>
              </div>

              <div className="seo-read">
                <p className="seo-read-title">
                  {product.meta_title
                    ? product.meta_title
                    : product.name &&
                      product.name.substring(0, metaTitleMaxLength)}
                </p>
                <p className="seo-read-url">
                  {BUYER_APP}/products/
                  {getSlugURL(product.name)}...
                </p>
                <p className="seo-read-description">
                  {product.meta_description
                    ? product.meta_description
                    : product.description &&
                      removeHtmlTags(product.description).substring(
                        0,
                        metaDescMaxLength
                      )}
                </p>
              </div>

              <div
                className={`product-seo-input ${openSeoEdit ? "" : "close"}`}
              >
                <div className="single-row">
                  <label className="meta-label">
                    <span>Meta Title</span>
                    <span>
                      {metaTitleRemaining}/{metaTitleMaxLength}
                    </span>
                  </label>
                  <input
                    value={product.meta_title}
                    onChange={(e) => {
                      e.preventDefault();
                      handleInputChange(e);
                      const length = e.target.value.length;
                      setMetaTitleRemaining(metaTitleMaxLength - length);
                    }}
                    className="form-control"
                    type="text"
                    name="meta_title"
                    minLength={3}
                    maxLength={metaTitleMaxLength}
                  />
                </div>
                <div className="single-row">
                  <label className="meta-label">
                    <span>Meta Description</span>
                    <span>
                      {metaDescRemaining}/{metaDescMaxLength}
                    </span>
                  </label>
                  <textarea
                    value={product.meta_description}
                    name="meta_description"
                    id="meta_description"
                    className="form-control meta-description-input"
                    onChange={(e) => {
                      e.preventDefault();
                      handleInputChange(e);
                      const length = e.target.value.length;
                      setMetaDescRemaining(metaDescMaxLength - length);
                    }}
                    minLength={3}
                    maxLength={metaDescMaxLength}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div className="product-right-area">
            {/* status of product box */}
            <div>
              <label>
                Status
                <span className="red"> *</span>
              </label>
              <select
                id="product-create-update-status-select"
                className="form-control-select-input"
                value={product.status}
                onChange={(e) => {
                  const val = e.target.value;
                  setProduct((prev) => ({
                    ...prev,
                    status: val,
                  }));
                  setProductUpdate(1);
                }}
              >
                <option value="Active">Active</option>
                <option value="Draft">Draft</option>
              </select>
            </div>

            {/* Product organization */}
            <div>
              <p className="productOrgPara">Product organization</p>
              <div>
                <div className="col">
                  <label>
                    Condition
                    {product.status === "Active" && (
                      <span className="red"> *</span>
                    )}
                  </label>
                  <select
                    id="product-create-update-condition-select"
                    name="condition"
                    value={product.condition}
                    onChange={handleInputChange}
                    className="form-control-select-input"
                  >
                    {conditionData.length === 0 ? (
                      <option>No data</option>
                    ) : (
                      <>
                        <option>Select</option>
                        {conditionData.map((conditions) => (
                          <option
                            id="sellers-productsConditions-options"
                            key={conditions}
                            value={conditions}
                          >
                            {conditions}
                          </option>
                        ))}
                      </>
                    )}
                  </select>
                  <label id="condition_error" className="red">
                    {errors.condition}
                  </label>
                </div>
                <div className="col">
                  <label>
                    Applicable Age Group
                    {product.status === "Active" && (
                      <span className="red"> *</span>
                    )}
                  </label>
                  <select
                    id="product-create-update-applicable-age-group-select"
                    className="form-control-select-input"
                    name="age_group_id"
                    value={product.age_group_id}
                    onChange={handleInputChange}
                  >
                    {applicableAgeGroupData.length === 0 ? (
                      <option>No data</option>
                    ) : (
                      <>
                        <option>Select</option>
                        {applicableAgeGroupData.map((ageGroup) => (
                          <option
                            id="sellers-productApplicableAgeOptions"
                            key={ageGroup.id}
                            value={ageGroup.id}
                          >
                            {ageGroup.title}
                          </option>
                        ))}
                      </>
                    )}
                  </select>
                  <label id="age_group_error" className="red">
                    {errors.age_group_id}
                  </label>
                </div>
                <div className="col">
                  <label>Brand</label>
                  <select
                    id="product-create-update-brand-select"
                    className="form-control-select-input"
                    name="brand_id"
                    value={product.brand_id}
                    onChange={handleInputChange}
                  >
                    {brandData.length === 0 ? (
                      <option>No data</option>
                    ) : (
                      <>
                        <option>Select</option>
                        {brandData.map((brands) => (
                          <option
                            id="sellers-productBrandsOptions"
                            key={brands.id}
                            value={brands.id}
                          >
                            {brands.name}
                          </option>
                        ))}
                      </>
                    )}
                  </select>
                </div>
                <div className="col">
                  <div className="mediaHeadingDiv">
                    <p
                      className="imges-guide category-spain-guide"
                      onClick={handleCategoryDetailsPopup}
                    >
                      <label>
                        Category
                        {product.status === "Active" && (
                          <span className="red"> *</span>
                        )}
                      </label>
                      <span className="Category_information_tag">
                        <svg
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.5 1.1C4.33305 1.1 3.21389 1.56357 2.38873 2.38873C1.56357 3.21389 1.1 4.33305 1.1 5.5C1.1 6.66695 1.56357 7.78611 2.38873 8.61127C3.21389 9.43643 4.33305 9.9 5.5 9.9C6.66695 9.9 7.78611 9.43643 8.61127 8.61127C9.43643 7.78611 9.9 6.66695 9.9 5.5C9.9 4.33305 9.43643 3.21389 8.61127 2.38873C7.78611 1.56357 6.66695 1.1 5.5 1.1ZM0 5.5C0 2.46235 2.46235 0 5.5 0C8.53765 0 11 2.46235 11 5.5C11 8.53765 8.53765 11 5.5 11C2.46235 11 0 8.53765 0 5.5Z"
                            fill="#A4A4A4"
                          />
                          <path
                            d="M5.50078 4.4002C5.64665 4.4002 5.78654 4.45814 5.88969 4.56129C5.99283 4.66443 6.05078 4.80433 6.05078 4.9502V8.2502C6.05078 8.39606 5.99283 8.53596 5.88969 8.6391C5.78654 8.74225 5.64665 8.8002 5.50078 8.8002C5.35491 8.8002 5.21502 8.74225 5.11187 8.6391C5.00873 8.53596 4.95078 8.39606 4.95078 8.2502V4.9502C4.95078 4.80433 5.00873 4.66443 5.11187 4.56129C5.21502 4.45814 5.35491 4.4002 5.50078 4.4002ZM6.32578 3.0252C6.32578 3.244 6.23886 3.45384 6.08414 3.60856C5.92943 3.76328 5.71958 3.8502 5.50078 3.8502C5.28198 3.8502 5.07214 3.76328 4.91742 3.60856C4.7627 3.45384 4.67578 3.244 4.67578 3.0252C4.67578 2.80639 4.7627 2.59655 4.91742 2.44183C5.07214 2.28711 5.28198 2.2002 5.50078 2.2002C5.71958 2.2002 5.92943 2.28711 6.08414 2.44183C6.23886 2.59655 6.32578 2.80639 6.32578 3.0252Z"
                            fill="#A4A4A4"
                          />
                        </svg>
                      </span>
                    </p>
                  </div>
                  <select
                    id="product-create-update-category-select"
                    className="form-control-select-input"
                    name="category_id"
                    value={product.category_id}
                    onChange={handleInputChange}
                  >
                    {categoryData.length === 0 ? (
                      <option>No data</option>
                    ) : (
                      <>
                        <option>Select</option>
                        {categoryData.map((category) => (
                          <option
                            id="sellers-productsCategoryOptions"
                            key={category.id}
                            value={category.id}
                          >
                            {category.name}
                          </option>
                        ))}{" "}
                      </>
                    )}
                  </select>
                  <label id="category_id_error" className="red">
                    {errors.category_id}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="product-btn-area">
          <button
            type="button"
            id="product-create-update-discard-btn"
            className="btn secondary-btn"
            onClick={() => {
              navigate("/products");
            }}
          >
            Discard
          </button>
          <button
            type="submit"
            id="product-create-update-save-btn"
            className="btn primary-btn"
            disabled={sellerStatus}
            style={{
              opacity: sellerStatus ? 0.5 : 1,
              pointerEvents: sellerStatus ? "none" : "",
            }}
            onClick={productDataHandleSubmit}
          >
            {props.page === "edit" ? "Update" : "Save"}
          </button>
        </div>
        {/* </form> */}
      </div>
      {showCategoriesPopup && (
        <CategoriesDescription
          closedCategoryDetailsPopup={closedCategoryDetailsPopup}
        />
      )}
      {/* image upload modal */}
      {showImgUpModal && (
        <div className="modal fade">
          <div className="img-modal-content">
            <div className="img-modal-header">
              <p>Image upload</p>
              <button
                className="modal-close-icon"
                onClick={() => {
                  setShowImgUpModal(false);
                }}
              >
                <FontAwesomeIcon icon={faXmark} />
              </button>
            </div>
            <div className="modal-body">
              <div className="modal-img-editor">
                <Cropper
                  src={rawImage}
                  ref={cropperRef}
                  initialAspectRatio={1}
                  aspectRatio={rawImgName === "thumb_image" ? 1 : undefined}
                  preview=".img-preview"
                  viewMode={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false}
                  guides={true}
                  style={{ maxHeight: 350 }}
                />
                <div
                  className="img-preview"
                  style={{
                    width: "100%",
                    float: "left",
                    height: "300px",
                    border: "1px solid #ced4da",
                    maxHeight: 350,
                  }}
                />
              </div>
            </div>
            <div className="modal-buttons">
              <button
                className="btn secondary-btn"
                onClick={() => {
                  setShowImgUpModal(false);
                }}
              >
                Cancel
              </button>
              <button className="btn primary-btn" onClick={cropImage}>
                Upload
              </button>
            </div>
          </div>
        </div>
      )}

      {/* page loading modal */}
      {isLoading && (
        <div className="modal fade">
          <div className="modal-content-loader">
            <img src={loadingImgPath} className="loader-img-size" />
          </div>
        </div>
      )}
    </div>
  );
};

export default NewProductsVariant;
